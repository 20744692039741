import {
  DeleteOutlined,
  EditOutlined,
  EllipsisOutlined,
  InfoCircleOutlined,
  QuestionCircleOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import {
  Tooltip as AntDToolTip,
  Button,
  Col,
  Descriptions,
  Dropdown,
  Flex,
  Popconfirm,
  Popover,
  Row,
  Select,
  Skeleton,
  Space,
  Table,
  Tag,
  Tooltip,
  message,
} from "antd";
import {
  customFieldTypeFormatFunctions,
  formatCurrency,
  formatDate,
  getCustomFieldsItems,
  idToObjectMap,
} from "common/utils";
import { AktDatePicker } from "components/aktDatePicker";
import { TriggerModal } from "components/triggerModal";
import currency from "currency.js";
import {
  useAssignAccountsMutation,
  useFetchDebtorProfileQuery,
} from "features/accountsTable/agencyPortal/accountsTableAPI";
import { TableAppearanceModal } from "features/appearance";
import { reconcileColumns } from "features/appearance/components/tableAppearanceModal";
import { TABLE_COLUMNS } from "features/appearance/constants";
import { useUserType } from "features/auth";
import { useFetchMeQuery } from "features/auth/authAPI";
import { useFetchAgencyQuery } from "features/basicInfo/agencyPortal/agencySettingsAPI";
import { useFetchCreditorsQuery } from "features/creditors/agencyPortal/creditorsAPI";
import {
  useDeleteAccountMutation,
  useFetchDebtorAccountsQuery,
  usePostAssignAccountsToForwardingEntityMutation,
  useUpdateAccountStatusMutation,
  useUpdateFollowUpDateMutation,
} from "features/debtorAccountsTable/agencyPortal/debtorAccountsAPI";
import AccountSequencesModal from "features/debtorAccountsTable/components/accountSequencesModal";
import BalanceHistoryModal from "features/debtorAccountsTable/components/balanceHistoryModal";
import CreditBureauSettingsModal from "features/debtorAccountsTable/components/creditBureauSettingsModal";
import CreditReportHistoryModal from "features/debtorAccountsTable/components/creditReportHistoryModal";
import ManageCodebtorsModal from "features/debtorAccountsTable/components/manageCodebtorsModal";
import ManageDisputeModal from "features/debtorAccountsTable/components/manageDisputeModal";
import UpdateAccountModal from "features/debtorProfile/components/updateAccountModal";
import { useFetchForwardingEntitiesQuery } from "features/forwardingEntities/forwardingEntitiesAPI";
import {
  collectorsWithRolesSelector,
  useFetchAgencyWorkflowStates,
  useFetchCollectorsQuery,
} from "features/home/agencyPortal/homeAPI";
import { collectorFullName } from "features/home/utils";
import { PERMISSIONS } from "features/permissions";
import useAuthorizations from "features/permissions/hooks/useAuthorizations";
import { useFetchAllAccountUdfCustomFieldsQuery } from "features/userDefinedFields/accountUserDefinedFieldsAPI";
import { useAppearance } from "providers/appearanceProvider";
import { useCallback, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

const StyledTable = styled(Table)`
  cursor: pointer;
  width: 100%;
  & .ant-table-cell:not(th) {
    padding: 2px 8px !important;
  }
`;
const StyledTag = styled(Tag)`
  margin-bottom: 4px;
  text-transform: uppercase;
`;

const StyledInfoTitle = styled.div`
  color: grey;
`;

const StyledInfoValue = styled.div`
  margin-left: 4px;
`;

const StyledInfoCircleOutlined = styled(InfoCircleOutlined)`
  color: #1677ff;
  margin-left: 4px;
`;

const StyledQuestionCircleOutlined = styled(QuestionCircleOutlined)`
  color: #1677ff;
  margin-left: 4px;
`;

const StyledButton = styled(Button)`
  padding-left: 0;
`;

const StyledEllipsisOutlined = styled(EllipsisOutlined)`
  font-size: 24px;
`;

const TABLE_ID = "debtorAccounts";

function DebtorAccountsTable({ debtorId }) {
  const { isCreditorUserType, isAgencyUserType } = useUserType();
  const [activeRecord, setActiveRecord] = useState(undefined);
  const [isDisputeModalVisible, setDisputeModalVisible] = useState(false);
  const [isBalanceHistoryModalVisible, setBalanceHistoryModalVisible] = useState(false);
  const [isCreditBureauSettingsModalVisible, setCreditBureauSettingsModalVisible] = useState(false);
  const [isManageCodebtorsModalVisible, setManageCodebtorsModalVisible] = useState(false);
  const [isUpdateAccountModalVisible, setUpdateAccountModalVisible] = useState(false);
  const [isSequencesModalVisible, setSequencesModalVisible] = useState(false);
  const [isCreditReportHistoryModalVisible, setCreditReportHistoryModalVisible] = useState(false);
  const navigate = useNavigate();
  const [assignAccountsToAgent] = useAssignAccountsMutation();
  const [updateFollowUpDate] = useUpdateFollowUpDateMutation();
  const [deleteAccount] = useDeleteAccountMutation();
  const [assignAccountsToForwardingEntity] = usePostAssignAccountsToForwardingEntityMutation();
  const [updateAccountStatus] = useUpdateAccountStatusMutation();
  const { data: debtorAccounts, isLoading, isFetching } = useFetchDebtorAccountsQuery({ debtorId });
  const { data: debtorPersonalInfo } = useFetchDebtorProfileQuery({ debtorId });
  const { data: agencySettings } = useFetchAgencyQuery();
  const { collectorsWithRoles: collectors } = useFetchCollectorsQuery(undefined, {
    selectFromResult: (result) => ({
      ...result,
      collectorsWithRoles: collectorsWithRolesSelector(result),
    }),
  });
  const { data: forwardingEntities } = useFetchForwardingEntitiesQuery();
  const { data: creditors } = useFetchCreditorsQuery(
    { filters: { creditorIds: debtorAccounts?.map((account) => account.creditorId) } },
    { skip: !debtorAccounts || debtorAccounts?.length === 0 },
  );
  const { data: defaultWorkflowStates } = useFetchAgencyWorkflowStates();
  const {
    data: {
      [PERMISSIONS.ACCOUNT__DELETE]: isAccountDeleteAuthorized,
      [PERMISSIONS.ACCOUNT__UPDATE_FOLLOW_UP_DATE]: isChangeFollowUpDateAuthorized,
      [PERMISSIONS.ACCOUNT__UPDATE_FORWARDING_ENTITY]: isChangeForwardingAgencyAuthorized,
      [PERMISSIONS.ACCOUNT__UPDATE_COLLECTOR]: isChangeCollectorAuthorized,
      [PERMISSIONS.ACCOUNT__UPDATE_GENERAL_INFO]: isAccountEditAuthorized,
      [PERMISSIONS.ACCOUNT__UPDATE_STATUS_CODE]: isChangeStatusCodeAuthorized,
      [PERMISSIONS.ACCOUNT_BALANCE_HISTORY__GET]: isViewBalanceHistory,
      [PERMISSIONS.ACCOUNT_CBR_CONFIG__GET]: isViewCreditBureauAuthorized,
      [PERMISSIONS.ACCOUNT_CODEBTORS__GET]: isViewCodebtorsAuthorized,
      [PERMISSIONS.ACCOUNT_COMMUNICATION_SEQUENCE__GET]: isAccountCommunicationSequenceAuthorized,
      [PERMISSIONS.ACCOUNT_CREDIT_REPORT_HISTORY__GET]: isViewCreditReportHistoryAuthorized,
      [PERMISSIONS.DISPUTE__GET]: isDisputeGetAuthorized,
    },
  } = useAuthorizations();

  const { data: me } = useFetchMeQuery();
  const { data: accountCustomFields } = useFetchAllAccountUdfCustomFieldsQuery();
  const { appearance, isLoading: isAppearanceLoading } = useAppearance();

  const creditorIdToCreditorMap = useMemo(() => {
    if (!creditors) {
      return {};
    }
    return idToObjectMap(creditors.results);
  }, [creditors]);

  const isChangeFollowUpDateDisabled = (record) => {
    return record.status === "judgment" || isCreditorUserType || !isChangeFollowUpDateAuthorized;
  };
  const isChangeStatusCodeDisabled = (record) => {
    return record.status === "judgment" || isCreditorUserType || !isChangeStatusCodeAuthorized;
  };
  const isChangeTurnoverDateDisabled = (record) => {
    return record.status === "judgment" || isCreditorUserType;
  };
  const isChangeForwardingAgencyDisabled = (record) => {
    return (
      record.status === "judgment" || isCreditorUserType || !isChangeForwardingAgencyAuthorized
    );
  };
  const isChangeCollectorDisabled = (record) => {
    return record.status === "judgment" || isCreditorUserType || !isChangeCollectorAuthorized;
  };

  const onDoneCreditBureauSettingsModal = async () => {
    setCreditBureauSettingsModalVisible(false);
    setActiveRecord(null);
  };

  const onUpdateAccountStatus = async (record, newStatus) => {
    const result = await updateAccountStatus({
      workflowTaskId: record.workflowTaskId,
      stateId: newStatus.id,
    });
    if ("data" in result) {
      message.success("Account status changed successfully!");
    }
  };

  const onChangeFollowUpDate = async (accountId, date) => {
    const result = await updateFollowUpDate({ followUpDate: date, accountId });
    if ("data" in result) {
      message.success("Follow-up date changed successfully!");
    }
  };

  const onChangeAssignAgent = async ({ selectedAccountId, agentId }) => {
    const result = await assignAccountsToAgent({
      accountIds: [selectedAccountId],
      agentId,
    });
    if ("data" in result) {
      const assignee = collectors?.find((collector) => collector.id === agentId);
      if (!assignee) {
        message.success("Collector was unassigned successfully!");
      } else {
        message.success(`${collectorFullName(assignee)} was assigned successfully!`);
      }
    }
  };

  const onChangeForwardingEntity = async ({ selectedAccountId, forwardingEntityId }) => {
    const result = await assignAccountsToForwardingEntity({
      accountIds: [selectedAccountId],
      forwardingEntityId,
    });
    if ("data" in result) {
      const assignedForwardingEntity = forwardingEntities?.find(
        (forwardingEntity) => forwardingEntity.id === forwardingEntityId,
      );
      if (!assignedForwardingEntity) {
        message.success("Forwarding entity was unassigned successfully!");
      } else {
        message.success(`${assignedForwardingEntity.name} was assigned successfully!`);
      }
    }
  };

  const filterOption = (inputValue, option) => {
    const fullOptionText = option.label;
    return fullOptionText?.toLowerCase().includes(inputValue?.toLowerCase());
  };

  const onDoneSequencesModal = () => {
    setSequencesModalVisible(false);
    setActiveRecord(null);
  };

  const onCreditBureauSettingsModalCancel = () => {
    setCreditBureauSettingsModalVisible(false);
    setActiveRecord(null);
  };

  const onManageCodebtorsModalCancel = () => {
    setManageCodebtorsModalVisible(false);
    setActiveRecord(null);
  };

  const onDoneUpdateAccountModal = () => {
    setUpdateAccountModalVisible(false);
    setActiveRecord(null);
  };

  const onUpdateAccountModalCancel = () => {
    setUpdateAccountModalVisible(false);
    setActiveRecord(null);
  };

  const onSequencesModalCancel = () => {
    setSequencesModalVisible(false);
    setActiveRecord(null);
  };

  const onBalanceHistoryModalCancel = () => {
    setBalanceHistoryModalVisible(false);
    setActiveRecord(null);
  };

  const onManageDisputeModalCancel = () => {
    setDisputeModalVisible(false);
    setActiveRecord(null);
  };

  const onCreditReportHistoryModalCancel = () => {
    setCreditReportHistoryModalVisible(false);
    setActiveRecord(null);
  };

  const dropdownItems = (record = null) =>
    [
      {
        label: "Configure Credit Bureau Settings",
        key: "bureau",
        hidden: !isViewCreditBureauAuthorized,
      },
      {
        label: "Manage Codebtors",
        key: "codebtors",
        hidden: !isViewCodebtorsAuthorized,
      },
      {
        label: "Manage Communication Sequence",
        key: "sequences",
        hidden: !isAccountCommunicationSequenceAuthorized,
      },
      {
        label: "View Balance History",
        key: "balances",
        hidden: !isViewBalanceHistory,
      },
      {
        label: record?.dispute?.status ? "Manage Dispute" : "Open Dispute",
        key: "dispute",
        hidden: !isDisputeGetAuthorized,
      },
      {
        label: "View Credit Report History",
        key: "creditReport",
        hidden: !isViewCreditReportHistoryAuthorized,
      },
    ]
      .filter((item) => !item.hidden)
      // insert dividers between items
      .flatMap((value, index, array) =>
        index < array.length - 1
          ? [
              value,
              {
                type: "divider",
              },
            ]
          : value,
      );

  const handleDelete = async (record) => {
    const result = await deleteAccount({ accountId: record.id });
    if ("data" in result) {
      message.success("Account removed successfully!");
    }
    if ("error" in result) {
      message.error("Unable to remove account");
    }
  };

  const configureCreditBureauSettings = (record) => {
    setCreditBureauSettingsModalVisible(true);
    setActiveRecord(record);
  };

  const configureCodebtors = (record) => {
    setManageCodebtorsModalVisible(true);
    setActiveRecord(record);
  };

  const configureSequences = (record) => {
    setSequencesModalVisible(true);
    setActiveRecord(record);
  };

  const viewBalanceHistory = (record) => {
    setBalanceHistoryModalVisible(true);
    setActiveRecord(record);
  };

  const viewCreditReportHistory = (record) => {
    setCreditReportHistoryModalVisible(true);
    setActiveRecord(record);
  };

  const manageDispute = (record) => {
    setDisputeModalVisible(true);
    setActiveRecord(record);
  };

  const handleMenuClick = ({ key, domEvent }, record) => {
    domEvent.preventDefault();
    domEvent.stopPropagation();

    // Call the action handler defined in the items dictionary
    const action = {
      bureau: configureCreditBureauSettings,
      codebtors: configureCodebtors,
      sequences: configureSequences,
      balances: viewBalanceHistory,
      dispute: manageDispute,
      creditReport: viewCreditReportHistory,
    }[key];
    action(record);
  };

  const onUpdateAccountClick = (record) => {
    setUpdateAccountModalVisible(true);
    setActiveRecord(record);
  };

  const defaultColumns = [
    {
      key: TABLE_COLUMNS.DEBTOR_ACCOUNTS__IDENTIFIERS,
      title: "Identifiers",
      fixed: "left",
      width: 220,
      render: (text, record) => (
        <Col>
          <Row align="middle">
            <StyledInfoTitle>Account ID:</StyledInfoTitle>
            <StyledInfoValue>{record?.id ?? "-"}</StyledInfoValue>
          </Row>
          <Row align="middle">
            <StyledInfoTitle>Debtor ID:</StyledInfoTitle>
            <StyledInfoValue>{record?.debtorExternalId ?? "-"}</StyledInfoValue>
          </Row>
          <Row align="middle">
            <StyledInfoTitle>External ID:</StyledInfoTitle>
            <StyledInfoValue>{record?.externalId ?? "-"}</StyledInfoValue>
          </Row>
          <Row align="middle">
            <StyledInfoTitle>Client Ref ID:</StyledInfoTitle>
            <StyledInfoValue>{record?.clientReferenceId ?? "-"}</StyledInfoValue>
          </Row>
        </Col>
      ),
      visible: true,
    },
    {
      key: TABLE_COLUMNS.DEBTOR_ACCOUNTS__TAGS,
      title: "Tags",
      fixed: "left",
      width: 0,
      render: (text, record) => (
        <Col>
          {record?.forwardingEntityId !== null ? (
            <StyledTag color="blue">forwarded</StyledTag>
          ) : null}
          {record?.dispute?.status && record?.dispute?.status !== "resolved" ? (
            <StyledTag color="red">disputed</StyledTag>
          ) : null}
          {record?.dispute?.status === "resolved" ? (
            <StyledTag color="green">resolved</StyledTag>
          ) : null}
          {record?.legalStatus === "in_litigation" ? (
            <StyledTag color="orange">in litigation</StyledTag>
          ) : null}
          {record?.legalStatus === "judgment_applied" ? (
            <StyledTag color="green">judgment applied</StyledTag>
          ) : null}
          {record?.legalStatus === "judgment" ? (
            <StyledTag color="green">JUDGMENT APPLIED</StyledTag>
          ) : null}
          {record?.legalStatus === "judgment_applied_archived" ? (
            <StyledTag color="green">JUDGMENT ARCHIVED</StyledTag>
          ) : null}
        </Col>
      ),
      visible: true,
    },
    {
      key: TABLE_COLUMNS.DEBTOR_ACCOUNTS__CURRENT_BALANCE,
      title: "Balance",
      children: [
        {
          title: "Original",
          width: 125,
          dataIndex: "originalTotal",
          render: (text, record) => {
            const balanceItems = [
              {
                key: "originalPrincipal",
                label: "Principal",
                value: formatCurrency(record.originalPrincipal),
              },
              {
                key: "originalInterest",
                label: `Interest (${
                  currency(record?.interestRate, { precision: 4 }).multiply(100).value
                }%)`,
                value: formatCurrency(record.originalInterest),
              },
              {
                key: "originalTotalFeesDue",
                label: "Fees",
                value: formatCurrency(record.originalTotalFeesDue),
              },
              {
                key: "originalTotal",
                label: <strong>Total</strong>,
                value: <strong>{formatCurrency(record.originalTotal)}</strong>,
              },
            ];
            const totalBalanceContent = (
              <Col>
                <Descriptions bordered column={1} size="small">
                  {balanceItems.map((item) => (
                    <Descriptions.Item key={item.key} label={item.label}>
                      {item.value}
                    </Descriptions.Item>
                  ))}
                </Descriptions>
              </Col>
            );
            return (
              <Row align="middle">
                <StyledInfoValue>{formatCurrency(text)}</StyledInfoValue>
                <Popover
                  content={totalBalanceContent}
                  title="Original Balance Breakdown"
                  trigger="hover"
                  placement="right"
                >
                  <StyledQuestionCircleOutlined />
                </Popover>
              </Row>
            );
          },
        },
        {
          title: "Turnover",
          width: 125,
          dataIndex: "turnoverTotalBalance",
          render: (text, record) => formatCurrency(text),
        },
        {
          title: "Current",
          width: 125,
          dataIndex: "totalBalance",
          render: (text, record) => {
            const balanceItems = [
              {
                key: "principal",
                label: "Principal",
                value: formatCurrency(record.principalBalance),
              },
              {
                key: "interest",
                label: `Interest (${
                  currency(record?.interestRate, { precision: 4 }).multiply(100).value
                }%)`,
                value: formatCurrency(record.interestBalance),
              },
              {
                key: "totalFees",
                label: "Fees",
                value: formatCurrency(record.totalFeesBalance),
              },
              {
                key: "total",
                label: <strong>Total</strong>,
                value: <strong>{formatCurrency(text)}</strong>,
              },
            ];
            const totalBalanceContent = (
              <Col>
                <Descriptions bordered column={1} size="small">
                  {balanceItems.map((item) => (
                    <Descriptions.Item key={item.key} label={item.label}>
                      {item.value}
                    </Descriptions.Item>
                  ))}
                </Descriptions>
              </Col>
            );
            return (
              <Row align="middle">
                <StyledInfoValue>{formatCurrency(text)}</StyledInfoValue>
                <Popover
                  content={totalBalanceContent}
                  title="Total Balance Breakdown"
                  trigger="hover"
                  placement="right"
                >
                  <StyledQuestionCircleOutlined />
                </Popover>
              </Row>
            );
          },
          sorter: (a, b) => a.totalBalance - b.totalBalance,
        },
      ],
      visible: true,
    },
    {
      key: TABLE_COLUMNS.DEBTOR_ACCOUNTS__LAST_PAYMENT,
      title: "Last Payment",
      children: [
        {
          title: "Amount",
          dataIndex: "lastPaymentAmount",
          key: "lastPaymentAmount",
          render: (text) => (parseFloat(text) ? formatCurrency(text) : "-"),
        },
        {
          title: "Date",
          dataIndex: "lastPaymentDate",
          key: "lastPaymentDate",
          render: (text) => (text ? formatDate(text) : "-"),
        },
      ],
      visible: true,
    },
    {
      key: TABLE_COLUMNS.DEBTOR_ACCOUNTS__CLIENT,
      title: "Client",
      width: 175,
      render: (text, record) => {
        const creditor = creditorIdToCreditorMap[record.creditorId];
        const creditorInfo = (
          <>
            <Row align="middle">
              <StyledInfoTitle>Code:</StyledInfoTitle>
              <StyledInfoValue>
                <a href={`/creditors/${creditor?.id}`} target="_blank" rel="noreferrer">
                  {creditor?.code}
                </a>
              </StyledInfoValue>
            </Row>
            <Row align="middle">
              <StyledInfoTitle>Primary Contact's Name:</StyledInfoTitle>
              <StyledInfoValue>{creditor?.bestContact?.name || "-"}</StyledInfoValue>
            </Row>
            <Row align="middle">
              <StyledInfoTitle>Primary Contact's Email:</StyledInfoTitle>
              <StyledInfoValue>{creditor?.bestContact?.email || "-"}</StyledInfoValue>
            </Row>
            <Row align="middle">
              <StyledInfoTitle>Primary Contact's Phone:</StyledInfoTitle>
              <StyledInfoValue>{creditor?.bestContact?.workPhone || "-"}</StyledInfoValue>
            </Row>
            <Row align="middle">
              <StyledInfoTitle>Address:</StyledInfoTitle>
              <StyledInfoValue>{creditor?.bestAddress?.formatted || "-"}</StyledInfoValue>
            </Row>
          </>
        );

        const creditorContent = (
          <>
            <StyledInfoValue>{creditor?.name}</StyledInfoValue>
            <Popover content={creditorInfo} title={creditor?.name} trigger="click">
              <StyledInfoCircleOutlined />
            </Popover>
          </>
        );

        return creditorContent;
      },
      hideForRolledUpAccounts: true,
      hidden: isCreditorUserType,
      visible: true,
      sorter: {
        fields: ["creditor__name", "creditor__code"],
        compare: (a, b) => {
          const aName = creditorIdToCreditorMap[a.creditorId]?.name;
          const bName = creditorIdToCreditorMap[b.creditorId]?.name;

          if (aName < bName) {
            return -1;
          }
          if (aName > bName) {
            return 1;
          }
          return 0;
        },
      },
    },
    {
      key: TABLE_COLUMNS.DEBTOR_ACCOUNTS__ORIGINAL_CLIENT,
      title: "Original Creditor",
      width: 175,
      render: (text, record) => record.originalCreditorName,
      hideForRolledUpAccounts: true,
      hidden: isCreditorUserType,
      visible: true,
    },
    {
      key: TABLE_COLUMNS.DEBTOR_ACCOUNTS__CURRENT_CLIENT,
      title: "Current Creditor",
      width: 175,
      render: (text, record) => record.currentCreditorName,
      hideForRolledUpAccounts: true,
      hidden: isCreditorUserType,
      visible: true,
    },
    {
      key: TABLE_COLUMNS.DEBTOR_ACCOUNTS__FOLLOW_UP_DATE,
      title: "Follow-up Date",
      dataIndex: "followUpDate",
      render: (text, record) => (
        <AktDatePicker
          disabled={isChangeFollowUpDateDisabled(record)}
          value={text}
          onChange={(date) => {
            if (date !== text) {
              onChangeFollowUpDate(record.id, date);
            }
          }}
        />
      ),
      hideForRolledUpAccounts: true,
      visible: true,
      sorter: {
        compare: (a, b) => {
          if (a.followUpDate < b.followUpDate) {
            return -1;
          }
          if (a.followUpDate > b.followUpDate) {
            return 1;
          }
          return 0;
        },
      },
    },
    {
      key: TABLE_COLUMNS.DEBTOR_ACCOUNTS__STATUS,
      title: "Status",
      dataIndex: "statusName",
      render: (text, record) => {
        return (
          <Select
            showSearch
            filterOption={filterOption}
            disabled={isChangeStatusCodeDisabled(record)}
            value={!text ? "" : `${record.statusCode} - ${text}`}
            popupMatchSelectWidth={400}
            style={{
              width: 200,
            }}
            onChange={async (value) => {
              const newStatus = defaultWorkflowStates?.find((state) => state.id === value);
              if (text?.toLowerCase() !== newStatus?.name?.toLowerCase()) {
                await onUpdateAccountStatus(record, newStatus);
              }
            }}
            options={defaultWorkflowStates?.map((state) => ({
              value: state.id,
              label: [state.code, state.name].join(" - "),
            }))}
          />
        );
      },
      hideForRolledUpAccounts: true,
      visible: true,
      sorter: {
        compare: (a, b) => {
          if (a.statusName < b.statusName) {
            return -1;
          }
          if (a.statusName > b.statusName) {
            return 1;
          }
          return 0;
        },
      },
    },
    {
      key: TABLE_COLUMNS.DEBTOR_ACCOUNTS__CO_DEBTOR_IDS,
      title: "Co-Debtors",
      dataIndex: "debtors",
      render: (text, record) => {
        const codebtors = record?.debtors.filter((debtor) => debtor.id.toString() !== debtorId);
        if (codebtors.length === 0) {
          return "-";
        }

        return (
          <Col>
            {codebtors.map((codebtor) => (
              <div key={codebtor.id}>
                <Button
                  type="link"
                  onClick={() => {
                    navigate(`/debtors/${codebtor.id}`);
                  }}
                >
                  {codebtor.firstName} {codebtor.middleName} {codebtor.lastName} ({codebtor.id})
                </Button>
              </div>
            ))}
          </Col>
        );
      },
      visible: true,
    },
    {
      key: TABLE_COLUMNS.DEBTOR_ACCOUNTS__TURNOVER_DATE,
      title: "Turnover Date",
      dataIndex: "turnoverDate",
      render: (text, record) => (
        <StyledInfoValue>{formatDate(record?.turnoverDate) ?? "-"}</StyledInfoValue>
      ),
      hideForRolledUpAccounts: true,
      visible: false,
      sorter: {
        compare: (a, b) => {
          if (a.turnoverDate < b.turnoverDate) {
            return -1;
          }
          if (a.turnoverDate > b.turnoverDate) {
            return 1;
          }
          return 0;
        },
      },
    },
    {
      key: TABLE_COLUMNS.DEBTOR_ACCOUNTS__OTHER_KEY_DATES,
      title: "Other Key Dates",
      render: (text, record) => (
        <Col>
          <Row align="middle">
            <StyledInfoTitle>Service Date:</StyledInfoTitle>
            <StyledInfoValue>{formatDate(record?.dateOfService) ?? "-"}</StyledInfoValue>
          </Row>
          <Row align="middle">
            <StyledInfoTitle>Turnover Date:</StyledInfoTitle>
            <StyledInfoValue>{formatDate(record?.turnoverDate) ?? "-"}</StyledInfoValue>
          </Row>
          <Row align="middle">
            <StyledInfoTitle>Itemization Date:</StyledInfoTitle>
            <StyledInfoValue>{formatDate(record?.itemizationDate) ?? "-"}</StyledInfoValue>
          </Row>
          <Popover
            content={
              <>
                <Row align="middle">
                  <StyledInfoTitle>Service Date:</StyledInfoTitle>
                  <StyledInfoValue>{formatDate(record?.dateOfService) ?? "-"}</StyledInfoValue>
                </Row>
                <Row align="middle">
                  <StyledInfoTitle>Turnover Date:</StyledInfoTitle>
                  <StyledInfoValue>{formatDate(record?.turnoverDate) ?? "-"}</StyledInfoValue>
                </Row>
                <Row align="middle">
                  <StyledInfoTitle>Upload Date:</StyledInfoTitle>
                  <StyledInfoValue>{formatDate(record?.uploadDate) ?? "-"}</StyledInfoValue>
                </Row>
                <Row align="middle">
                  <StyledInfoTitle>Itemization Date:</StyledInfoTitle>
                  <StyledInfoValue>{formatDate(record?.itemizationDate) ?? "-"}</StyledInfoValue>
                </Row>
                <Row align="middle">
                  <StyledInfoTitle>Date of First Delinquency:</StyledInfoTitle>
                  <StyledInfoValue>
                    {formatDate(record?.dateOfFirstDelinquency) ?? "-"}
                  </StyledInfoValue>
                </Row>
                <Row align="middle">
                  <StyledInfoTitle>Charge-off Date:</StyledInfoTitle>
                  <StyledInfoValue>{formatDate(record?.chargeOffDate) ?? "-"}</StyledInfoValue>
                </Row>
                <Row align="middle">
                  <StyledInfoTitle>Interest Calculation Date:</StyledInfoTitle>
                  <StyledInfoValue>{formatDate(record?.interestStartDate) ?? "-"}</StyledInfoValue>
                </Row>
                <Row align="middle">
                  <StyledInfoTitle>Judgment Date:</StyledInfoTitle>
                  <StyledInfoValue>{formatDate(record?.judgmentDate) ?? "-"}</StyledInfoValue>
                </Row>
              </>
            }
            title="Account Dates"
            trigger="click"
          >
            <StyledButton type="link">See All</StyledButton>
          </Popover>
        </Col>
      ),
      visible: true,
    },
    {
      title: "Intelitech Scores",
      render: (text, record) => (
        <Col>
          <Row align="middle">
            <StyledInfoTitle>I-Score:</StyledInfoTitle>
            <StyledInfoValue>{record?.intelitechScore?.iScore ?? "-"}</StyledInfoValue>
          </Row>
          <Row align="middle">
            <StyledInfoTitle>Medal-Score:</StyledInfoTitle>
            <StyledInfoValue>{record?.intelitechScore?.medalScore ?? "-"}</StyledInfoValue>
          </Row>
          <Row align="middle">
            <StyledInfoTitle>Q-Score:</StyledInfoTitle>
            <StyledInfoValue>{record?.intelitechScore?.qScore ?? "-"}</StyledInfoValue>
          </Row>
          <Row align="middle">
            <StyledInfoTitle>R-Score:</StyledInfoTitle>
            <StyledInfoValue>{record?.intelitechScore?.rScore ?? "-"}</StyledInfoValue>
          </Row>
          <Row align="middle">
            <StyledInfoTitle>FPG-Value:</StyledInfoTitle>
            <StyledInfoValue>{record?.intelitechScore?.fpgValue ?? "-"}</StyledInfoValue>
          </Row>
        </Col>
      ),
      visible: agencySettings?.isIntelitechEnabled,
    },
    {
      key: TABLE_COLUMNS.DEBTOR_ACCOUNTS__COLLECTOR,
      title: "Collector",
      dataIndex: "assigneeId",
      render: (text, record) => (
        <Select
          showSearch
          filterOption={filterOption}
          disabled={isChangeCollectorDisabled(record)} // TODO: update when new status is defined on BE. All accounts that are 'rolled up' will not be editable
          popupMatchSelectWidth={false}
          value={text ?? ""}
          style={{
            width: 200,
          }}
          onChange={async (value) => {
            if (value !== text) {
              await onChangeAssignAgent({
                selectedAccountId: record.id,
                agentId: value === -1 ? null : value,
              });
            }
          }}
          options={[
            {
              label: "Unassigned",
              value: -1,
            },
            ...(collectors.map((collector) => ({
              label: collectorFullName(collector),
              value: collector.id,
            })) ?? []),
          ]}
          placeholder="Select one..."
        />
      ),
      hideForRolledUpAccounts: true,
      visible: true,
    },
    {
      key: TABLE_COLUMNS.DEBTOR_ACCOUNTS__FORWARDING_ENTITY,
      title: "Forwarding Entity",
      dataIndex: "forwardingEntityId",
      render: (text, record) => (
        <Select
          allowClear
          showSearch
          filterOption={filterOption}
          placeholder="Select one..."
          disabled={isChangeForwardingAgencyDisabled(record)}
          popupMatchSelectWidth={false}
          value={text ?? ""}
          style={{
            width: 200,
          }}
          onChange={async (value) => {
            if (value !== record.forwardingEntityId) {
              await onChangeForwardingEntity({
                selectedAccountId: record.id,
                forwardingEntityId: value ?? null,
              });
            }
          }}
          options={[
            ...(forwardingEntities?.map((forwardingEntity) => ({
              label: forwardingEntity.name,
              value: forwardingEntity.id,
            })) ?? []),
          ]}
        />
      ),
      hideForRolledUpAccounts: true,
      visible: true,
    },
    {
      key: TABLE_COLUMNS.DEBTOR_ACCOUNTS__USER_DEFINED_FIELDS,
      title: "User Defined Fields",
      dataIndex: "customFields",
      render: (_, record) => {
        const customFieldItems = getCustomFieldsItems(record.customFields, accountCustomFields);
        if (customFieldItems.length === 0) {
          return "-";
        }

        if (customFieldItems.length < 4) {
          return (
            <Col>
              {customFieldItems.map(({ field, slug, value }) => (
                <Row key={slug}>
                  <StyledInfoTitle>{field.name}:</StyledInfoTitle>
                  <StyledInfoValue>
                    {customFieldTypeFormatFunctions[field.type](value)}
                  </StyledInfoValue>
                </Row>
              ))}
            </Col>
          );
        }

        return (
          <Col>
            {customFieldItems.slice(0, 3).map(({ field, slug, value }) => (
              <Row key={slug}>
                <StyledInfoTitle>{field.name}:</StyledInfoTitle>
                <StyledInfoValue>
                  {customFieldTypeFormatFunctions[field.type](value)}
                </StyledInfoValue>
              </Row>
            ))}
            <Popover
              content={customFieldItems.map(({ field, slug, value }) => (
                <Row key={slug}>
                  <StyledInfoTitle>{field.name}:</StyledInfoTitle>
                  <StyledInfoValue>
                    {customFieldTypeFormatFunctions[field.type](value)}
                  </StyledInfoValue>
                </Row>
              ))}
              title="User Defined Fields"
              trigger="click"
            >
              <StyledButton type="link">See All</StyledButton>
            </Popover>
          </Col>
        );
      },
      visible: true,
    },
    {
      key: TABLE_COLUMNS.DEBTOR_ACCOUNTS__ACTIONS,
      title: "Actions",
      fixed: "right",
      align: "center",
      dataIndex: "action",
      render: (_, record) => (
        <Row wrap={false} align="middle">
          {/* <AntDToolTip placement="bottom" title="View Payment History">
              <HistoryOutlined
                key="history"
                onClick={() =>
                  setModalContent(
                    <MakeAPaymentModal
                      debts={debtorProfileSlice.accounts}
                      title="Debt"
                      open
                      onOk={paymentOnOk}
                      onCancel={onCancel}
                    />,
                  )
                }
              />
            </AntDToolTip> */}
          {isAgencyUserType && (
            <Space size="middle">
              {isAccountEditAuthorized && (
                <AntDToolTip placement="bottom" title="Edit" key="edit">
                  <EditOutlined key="edit" onClick={() => onUpdateAccountClick(record)} />
                </AntDToolTip>
              )}
              {isAccountDeleteAuthorized && (
                <Popconfirm
                  placement="topLeft"
                  okText="Yes"
                  title="Are you sure you want to delete this account?"
                  onConfirm={() => handleDelete(record)}
                >
                  <DeleteOutlined key="delete-account" />
                </Popconfirm>
              )}
            </Space>
          )}
          {dropdownItems(record).length > 0 && (
            <Dropdown
              menu={{
                // @ts-ignore
                items: dropdownItems(record),
                onClick: (clickHandlerProp) => handleMenuClick(clickHandlerProp, record),
              }}
              trigger={["click"]}
            >
              <Button type="text">
                <Space>
                  <StyledEllipsisOutlined />
                </Space>
              </Button>
            </Dropdown>
          )}
        </Row>
      ),
      hideForRolledUpAccounts: true,
      hidden:
        dropdownItems().length === 0 &&
        (!isAgencyUserType || (!isAccountEditAuthorized && !isAccountDeleteAuthorized)),
      visible: true,
    },
  ].filter((column) => !column.hidden);

  const reconciledColumns = reconcileColumns(defaultColumns, appearance?.items?.[TABLE_ID]).filter(
    (column) => column.visible,
  );

  const ExpandedRow = useCallback(
    (record) => {
      return (
        <>
          <h4>Rolled-up Accounts</h4>
          <StyledTable
            loading={isLoading}
            bordered
            scroll={{ x: "max-content" }}
            // @ts-ignore
            columns={reconciledColumns.filter((column) => !column.hideForRolledUpAccounts)}
            dataSource={debtorAccounts.filter(
              (debtorAccount) => debtorAccount.legalParentId === record.id,
            )}
            pagination={false}
          />
        </>
      );
    },
    // we don't want columns to be a dependency here
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [debtorAccounts, isLoading],
  );

  if (isAppearanceLoading) {
    return <Skeleton active />;
  }

  return (
    <>
      {isCreditBureauSettingsModalVisible && (
        <CreditBureauSettingsModal
          account={activeRecord}
          title="Credit Bureau Settings"
          open={isCreditBureauSettingsModalVisible}
          onOk={onDoneCreditBureauSettingsModal}
          onCancel={onCreditBureauSettingsModalCancel}
        />
      )}
      {isAgencyUserType && isUpdateAccountModalVisible && (
        <UpdateAccountModal
          account={activeRecord}
          title="Update Account"
          onCancel={onUpdateAccountModalCancel}
          onOk={onDoneUpdateAccountModal}
        />
      )}
      <AccountSequencesModal
        account={activeRecord}
        title={`Manage Communication Sequence (Account ID: ${activeRecord?.id})`}
        open={isSequencesModalVisible}
        onOk={onDoneSequencesModal}
        onCancel={onSequencesModalCancel}
        debtorId={debtorId}
      />
      {isManageCodebtorsModalVisible && (
        <ManageCodebtorsModal
          debtType={debtorPersonalInfo?.type}
          accountId={activeRecord.id}
          title="Manage Codebtors"
          open={isManageCodebtorsModalVisible}
          onCancel={onManageCodebtorsModalCancel}
          debtorId={debtorId}
        />
      )}
      {isDisputeModalVisible && (
        <ManageDisputeModal
          account={activeRecord}
          title={`Dispute Account (ID: ${activeRecord?.id})`}
          open={isDisputeModalVisible}
          onOk={onManageDisputeModalCancel}
          onCancel={onManageDisputeModalCancel}
          debtorId={debtorId}
        />
      )}
      {isCreditReportHistoryModalVisible && (
        <CreditReportHistoryModal
          account={activeRecord}
          title={`Credit Report History (Account ID: ${activeRecord?.id})`}
          open={isCreditReportHistoryModalVisible}
          onCancel={onCreditReportHistoryModalCancel}
          debtorId={debtorId}
        />
      )}
      {isBalanceHistoryModalVisible && (
        <BalanceHistoryModal
          account={activeRecord}
          open={isBalanceHistoryModalVisible}
          onCancel={onBalanceHistoryModalCancel}
          // @ts-ignore
          debtorId={debtorId}
        />
      )}
      {me?.isStaff && (
        <Flex justify="end">
          <TriggerModal
            modal={TableAppearanceModal}
            tableId={TABLE_ID}
            defaultColumns={defaultColumns}
            paginationSelector={false}
          >
            <Tooltip title="Customize Appearance">
              <StyledButton shape="circle" icon={<SettingOutlined />} type="text" />
            </Tooltip>
          </TriggerModal>
        </Flex>
      )}
      <StyledTable
        loading={isLoading || isFetching || isAppearanceLoading}
        bordered
        scroll={{ x: "max-content" }}
        // @ts-ignore
        columns={reconciledColumns}
        dataSource={debtorAccounts?.filter((debtorAccount) => !debtorAccount.legalParentId)}
        expandable={{
          expandedRowRender: ExpandedRow,
          rowExpandable: (record) =>
            // @ts-ignore
            debtorAccounts?.filter((debtorAccount) => debtorAccount.legalParentId === record?.id)
              .length > 0,
          showExpandColumn:
            debtorAccounts?.filter((debtorAccount) => debtorAccount.legalParentId).length > 0,
        }}
        // Hack for Antd table to only sort by ascending and descending and no third state https://github.com/ant-design/ant-design/issues/16747#issuecomment-612047300
        sortDirections={["ascend", "descend", "ascend"]}
      />
    </>
  );
}

export default DebtorAccountsTable;
