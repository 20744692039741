import { CaretDownOutlined, LogoutOutlined, UserOutlined } from "@ant-design/icons";
import { useAuth0 } from "@auth0/auth0-react";
import { Avatar, Divider, Dropdown, Layout, Modal, message } from "antd";
import config from "app/envConfig";
import CompanyLogo from "assets/aktos-logo-366x340-nowhitespace.png";
import { resetStateAction } from "common/redux/resetStateAction";
import { useFetchMeQuery } from "features/auth/authAPI";
import AppMenu from "layouts/agencyPortal/dashboard/menu/AppMenu/appMenu";
import { useWhiteLabel } from "providers/whiteLabelProvider";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

const { Header } = Layout;

const StyledCaretDownOutlined = styled(CaretDownOutlined)`
  margin-left: 6px;
  margin-right: 12px;
`;

const StyledAvatar = styled(Avatar)`
  background: #66a6ff;
  vertical-align: middle;
`;

const StyledLogo = styled("img")`
  float: left;
  height: 30px;
  margin: 17px 17px 17px 24px;
  background: #001529;
  cursor: pointer;
  // transform: scale(1.5);
  // margin-left: 6px;
`;
const StyledMenu = styled("span")`
  user-select: none;
  cursor: pointer;
`;

const StyledProfileMenu = styled(StyledMenu)`
  height: 100%;
  padding: 0 5px;
  color: #fff;
`;

const StyledUserFullname = styled("span")`
  color: lightgray;
`;

const StyledHeader = styled(Header)`
  background: #001529;
  width: 100%;
  min-width: 470px;
  position: fixed;
  z-index: 10;
  @media only screen and (max-width: 470px) {
    display: none;
  }
  &&& {
    padding-inline: 0;
  }
`;

const StyledUserActions = styled.div`
  background: #001529;
  float: right;
  border-bottom: 1px solid #f4f3f3;

  @media only screen and (max-width: 320px) {
    background: #fff;
    color: #404fc7;
  }
`;

function DashboardHeader() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { data: currentUser } = useFetchMeQuery();
  const { logout } = useAuth0();
  const { isWhiteLabeled, logo } = useWhiteLabel();

  const onLogout = () => {
    const logoutModal = Modal.confirm({
      title: "Logout",
      content: "Are you sure you want to log out?",
      icon: <LogoutOutlined />,
      onCancel: () => {
        /** No need to define body */
      },
      onOk: async () => {
        logoutModal.update({
          okButtonProps: { disabled: true },
          cancelButtonProps: { disabled: true },
        });

        await dispatch(resetStateAction());
        if (!config.useAuth0) {
          navigate("/login");
          message.success("Logged out successfully.", 2);
          return;
        }

        logout({
          logoutParams: {
            returnTo: `${window.location.origin}/login`,
          },
        });
        message.success("Logged out successfully.", 2);
      },
    });
  };

  const onChangePassword = () => {
    navigate("/change-password");
  };

  const items = [
    {
      key: "changePassword",
      label: "Change Password",
      actionHandler: onChangePassword,
    },
    {
      type: "divider",
    },
    {
      key: "logout",
      label: "Logout",
      actionHandler: onLogout,
    },
  ];

  const onDropdownClick = ({ item, key, keyPath, domEvent }) => {
    // This prevents navigation to invoice detail when clicking on a dropdown item
    domEvent.preventDefault();
    domEvent.stopPropagation();

    // Call the action handler defined in the items dictionary
    item.props.actionHandler();
  };

  return (
    <StyledHeader>
      <StyledLogo src={isWhiteLabeled ? logo : CompanyLogo} onClick={() => navigate("/accounts")} />
      <StyledUserActions>
        {/* <StyledSettingsMenu>
          <Badge count={10} dot>
            <BellOutlined style={{ fontSize: 18, color: "#000" }} />
          </Badge>
        </StyledSettingsMenu> */}
        {/* <Divider type="vertical" /> */}
        {currentUser?.email && (
          <StyledUserFullname>Logged in: {currentUser.email}</StyledUserFullname>
        )}
        <Divider type="vertical" style={{ background: "#fff" }} />
        <Dropdown
          menu={{
            // @ts-ignore
            items,
            onClick: (clickHandlerProp) => onDropdownClick(clickHandlerProp),
          }}
          trigger={["click"]}
        >
          <StyledProfileMenu>
            <StyledAvatar shape="circle" size="small" icon={<UserOutlined />} />
            <StyledCaretDownOutlined />
          </StyledProfileMenu>
        </Dropdown>
      </StyledUserActions>
      <AppMenu />
    </StyledHeader>
  );
}

export default DashboardHeader;
