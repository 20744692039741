import { Modal, Select, message } from "antd";
import { getFirstItem } from "common/utils";
import { selectAccountsTableSlice } from "features/accountsTable/accountsTableSlice";
import { usePostMergeDebtorsMutation } from "features/accountsTable/agencyPortal/accountsTableAPI";
import { useState } from "react";
import { useSelector } from "react-redux";

function MergeAccountsModal({ title, open, onOk, onCancel }) {
  const accountsTableSlice = useSelector(selectAccountsTableSlice);
  const [selectedDebtor, setSelectedDebtor] = useState(null);
  const [mergeDebtors, { isLoading }] = usePostMergeDebtorsMutation();

  const resetFields = () => {
    setSelectedDebtor(null);
  };

  const debtors = accountsTableSlice.selectedRows.map((row) => ({
    id: row.debtorId,
    name: row.debtorName,
  }));

  const onSubmit = async () => {
    // validate the form
    if (!selectedDebtor?.id) return;

    const selectedDebtorIds = debtors
      .map((debtor) => debtor.id)
      .filter((id) => id !== selectedDebtor.id);

    const result = await mergeDebtors({
      primaryDebtorId: selectedDebtor.id,
      secondaryDebtorId: getFirstItem(selectedDebtorIds),
    });
    if ("data" in result) {
      message.success(`Debtors merged successfully!`);
      onOk();
      resetFields();
    }
  };

  return (
    <Modal
      maskClosable={false}
      okButtonProps={{ loading: isLoading, disabled: isLoading }}
      title={title}
      open={open}
      onOk={onSubmit}
      onCancel={onCancel}
    >
      <span style={{ marginRight: "8px" }}>Debtor name to use:</span>
      <Select
        popupMatchSelectWidth={false}
        placeholder="Select a name..."
        value={selectedDebtor?.name}
        onChange={(value) => setSelectedDebtor(debtors.find((debtor) => debtor.id === value))}
        options={debtors.map((debtor) => ({
          value: debtor.id,
          label: `${debtor.name} (ID: ${debtor.id})`,
        }))}
      />
    </Modal>
  );
}

export default MergeAccountsModal;
