import { Form, Modal, message } from "antd";
import { useForm } from "antd/lib/form/Form";
import { DATE_FORMAT } from "common/constants";
import { formatError } from "common/redux/middleware/queryErrorLogger";
import { AktDateRangePicker } from "components/aktDatePicker";
import { useExportLettersMutation } from "features/documentCenter/documentCenterAPI";
import moment from "moment-timezone";
import styled from "styled-components";

const StyledForm = styled(Form)`
  margin-top: 12px;
  max-width: 350px;
`;

function DownloadLettersModal({ open, onOk, onCancel }) {
  const [form] = useForm();
  const [trigger, { isLoading: isLoadingNotice }] = useExportLettersMutation();

  const onSubmit = async () => {
    const values = await form.validateFields();
    const { uploadedDate } = values;
    const [startDate, endDate] = uploadedDate;

    const result = await trigger({ startDate, endDate });
    if ("data" in result) {
      await onOk();
      form.resetFields();
      if (result.data === null) {
        return message.warning("No letters scheduled to send out.");
      }
      return message.success(
        "Letter file is being generated. Please check back later in the Generated Documents section.",
      );
    }
    if ("error" in result) {
      form.setFields(formatError(result.error));
    }
  };

  return (
    <Modal
      maskClosable={false}
      title="Download Letter Vendor Export"
      open={open}
      confirmLoading={isLoadingNotice}
      onOk={onSubmit}
      okText="Generate"
      onCancel={onCancel}
    >
      <StyledForm
        layout="vertical"
        form={form}
        disabled={isLoadingNotice}
        initialValues={{
          uploadedDate: [
            moment().startOf("month").format(DATE_FORMAT),
            moment().endOf("day").format(DATE_FORMAT),
          ],
        }}
      >
        <Form.Item
          name="uploadedDate"
          label="Communication Scheduled Date"
          rules={[{ required: true, message: "Select uploaded date range" }]}
        >
          <AktDateRangePicker picker="date" allowClear />
        </Form.Item>
      </StyledForm>
    </Modal>
  );
}

export default DownloadLettersModal;
