import { PlusOutlined, QuestionCircleOutlined } from "@ant-design/icons";
import { Button, Col, Divider, FloatButton, Row, Table, Tag } from "antd";
import {
  camelToSnakeCase,
  capitalizeFirstLetter,
  formatCurrency,
  formatDate,
  idToObjectMap,
} from "common/utils";
import { CursorTable } from "components/cursorTable";
import { SearchWithDropdown } from "components/searchWithDropdown";
import {
  INITIAL_ORDER_BY,
  INITIAL_PAGE_SIZE,
  selectAccountsTableSlice,
  setOrderBy,
  setPagination,
  setSelectedRows,
  setVirtualPage,
} from "features/accountsTable/accountsTableSlice";
import AddManualCommercialAccountModal from "features/accountsTable/components/addManualCommercialAccountModal";
import AddManualConsumerAccountModal from "features/accountsTable/components/addManualConsumerAccountModal";
import CreditorAccountsTableFilters from "features/accountsTable/components/creditorAccountsTableFilters";
import SelectAddAccountsModeModal from "features/accountsTable/components/selectAddAccountsModeModal";
import {
  useFetchCreditorPortalWorklistAccountsQuery,
  useLazySearchCreditorPortalDebtorsByTypeQuery,
} from "features/accountsTable/creditorPortal/accountsTableAPI";
import { reconcileColumns } from "features/appearance/components/tableAppearanceModal";
import { TABLE_COLUMNS } from "features/appearance/constants";
import { useFetchCreditorPortalCreditorSummariesQuery } from "features/creditors/creditorPortal/creditorsAPI";
import { useFetchBackendConstantsQuery } from "features/home/agencyPortal/homeAPI";
import { useFetchClientSummaryQuery } from "features/home/creditorPortal/homeAPI";
import { collectorFullName } from "features/home/utils";
import { PERMISSIONS, useAuthorization } from "features/permissions";
import { useAppearance } from "providers/appearanceProvider";
import { useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";

const StyledQuestionCircleOutlined = styled(QuestionCircleOutlined)`
  color: #1677ff;
  margin-left: 4px;
`;

const StyledActionContainer = styled(Row)`
  background: #fff;
  height: 48px;
  margin-top: 1px;
  border-left: 1px solid #f0f0f0;
  align-items: center;
  flex: 1;
  min-width: 200px;
  display: flex;
  justify-content: space-between;
`;

const StyledTable = styled(CursorTable)`
  & .ant-checkbox-input {
    pointer-events: auto;
  }

  & .ant-table-selection-column {
    pointer-events: none;
  }

  & .ant-table-container {
    max-height: calc(100vh - 240px);
    overflow: scroll;
  }

  & .ant-table-cell {
    padding: 4px 8px !important;
  }
`;

const StyledCol = styled(Col)`
  overflow: auto;
`;

const StyledEmptyTable = styled(Table)`
  margin-top: 1px;
  margin-left: 1px;
`;

const StyledInfoValue = styled.div`
  margin-left: 4px;
`;

const StyledValue = styled.span`
  font-size: 12px;
  color: gray;
`;

const StyledHeader = styled.h2`
  color: #0b3948;
  margin-bottom: 0;
`;

const HeaderContainerCol = styled(Col)`
  margin-bottom: 12px;
`;

const StyledDivider = styled(Divider)`
  background-color: #cfc1c1;
`;

const StyledButton = styled(Button)`
  margin-top: 16px;
`;

const TABLE_ID = "accountWorklist";

function AccountsTable() {
  const dispatch = useDispatch();
  const { data: clientSummary } = useFetchClientSummaryQuery();
  const [isAddAccountModalVisible, setAddAccountModalVisible] = useState(false);
  const [isAddAccountManualModalVisible, setAddConsumerAccountManuallyModalVisible] =
    useState(false);
  const [isAddCommercialAccountManuallyModalVisible, setAddCommercialAccountManuallyModalVisible] =
    useState(false);
  const { data: creditors } = useFetchCreditorPortalCreditorSummariesQuery();

  const accountsTableSlice = useSelector(selectAccountsTableSlice);
  const { data: constants, isLoading: isConstantsLoading } = useFetchBackendConstantsQuery();
  const { isLoading: isAppearanceLoading, appearance } = useAppearance();

  const getOrderBy = useMemo(() => {
    // If the accountsTableSlice.orderBy is set to something besides INITIAL_ORDER_BY, then that means the user must've manually selected a column
    if (accountsTableSlice.orderBy !== INITIAL_ORDER_BY) {
      return accountsTableSlice.orderBy;
    }
    // If the defaultSortOrder is set in the appearance, find the table column that is the default sort column.
    const currentSorterFieldNames = appearance?.items?.[TABLE_ID]?.columns.find(
      (column) => column.key === appearance?.items?.[TABLE_ID]?.defaultSortOrder?.column,
    )?.sorterFieldNames;
    if (currentSorterFieldNames) {
      return currentSorterFieldNames
        .map((field) => {
          return `${
            appearance?.items?.[TABLE_ID]?.defaultSortOrder?.direction === "descend" ? "-" : ""
          }${camelToSnakeCase(field)}`;
        })
        .join(",");
    }
    // Otherwise, we default to the INITIAL_ORDER_BY.
    return INITIAL_ORDER_BY;
  }, [accountsTableSlice?.orderBy, appearance?.items]);

  const getPageSize = useMemo(() => {
    if (accountsTableSlice?.pageSize !== null) {
      return accountsTableSlice?.pageSize;
    }
    if (appearance?.items?.[TABLE_ID]?.pageSize) {
      return appearance?.items?.[TABLE_ID]?.pageSize;
    }
    return INITIAL_PAGE_SIZE;
  }, [accountsTableSlice?.pageSize, appearance?.items]);

  const onPaginationChange = (page, pageSize) => {
    dispatch(setPagination({ virtualPage: 1, prevToken: null, nextToken: null, pageSize }));
  };

  const { isLoading: isTableLoading, isFetching: isTableFetching } =
    useFetchCreditorPortalWorklistAccountsQuery(
      {
        orderBy: getOrderBy,
        pageSize: getPageSize,
        nextToken: accountsTableSlice.nextToken,
        prevToken: accountsTableSlice.prevToken,
        filters: accountsTableSlice.filters,
      },
      {
        refetchOnMountOrArgChange: true,
        skip: isAppearanceLoading,
      },
    );
  const { data: isAccountCreateAuthorized } = useAuthorization(PERMISSIONS.ACCOUNT__CREATE);
  const { data: isAccountGetAuthorized } = useAuthorization(PERMISSIONS.ACCOUNT__GET);

  // TODO: temporary commented out
  /* const { data: accountUdfCustomFields } = useFetchAllAccountUdfCustomFieldsQuery();
  const { data: debtorUdfCustomFields } = useFetchAllDebtorUdfCustomFieldsQuery();

  // cherry-pick udf that have "important" set to true
  const importantAccountUdfCustomFields = useMemo(
    () => accountUdfCustomFields?.filter((udf) => udf.isImportant),
    [accountUdfCustomFields],
  );
  const importantDebtorUdfCustomFields = useMemo(
    () => debtorUdfCustomFields?.filter((udf) => udf.isImportant),
    [debtorUdfCustomFields],
  );
  */

  const handleSetVirtualPage = (newVirtualPage) => {
    dispatch(setVirtualPage(newVirtualPage));
  };

  const creditorIdToCreditorMap = useMemo(() => {
    if (!creditors) {
      return {};
    }
    return idToObjectMap(creditors);
  }, [creditors]);

  const defaultColumns = [
    {
      key: TABLE_COLUMNS.ACCOUNT_WORKLIST__DEBTOR_ID,
      title: "Debtor ID",
      visible: true,
      dataIndex: "debtorExternalId",
      render: (text, record) => record?.debtorExternalId ?? "-",
      width: 150,
      sorter: {
        fields: ["debtor_id"],
      },
    },
    {
      key: TABLE_COLUMNS.ACCOUNT_WORKLIST__CLIENT_REFERENCE_ID,
      title: "Client Reference ID",
      dataIndex: "clientReferenceId",
      visible: true,
      render: (text, record) => record?.clientReferenceId ?? "-",
      width: 200,
      sorter: {},
    },
    {
      key: TABLE_COLUMNS.ACCOUNT_WORKLIST__EXTERNAL_ID,
      title: "External ID",
      visible: true,
      dataIndex: "externalId",
      render: (text, record) => record?.externalId ?? "-",
      width: 150,
      sorter: {},
    },
    {
      key: TABLE_COLUMNS.ACCOUNT_WORKLIST__FOLLOW_UP_DATE,
      title: "Follow-Up Date",
      visible: true,
      width: 160,
      dataIndex: "followUpDate",
      render: (text, record) => formatDate(record.followUpDate),
      sorter: {
        compare: (a, b) => {
          if (a.followUpDate < b.followUpDate) {
            return -1;
          }
          if (a.followUpDate > b.followUpDate) {
            return 1;
          }
          return 0;
        },
      },
    },
    {
      key: TABLE_COLUMNS.ACCOUNT_WORKLIST__DEBTOR,
      title: "Debtor",
      visible: true,
      width: 250,
      dataIndex: "debtorName",
      render: (text, record) => {
        if (record.isNew && record.isPriority) {
          return (
            <>
              <Tag color="red">NEW</Tag>
              <Tag color="green">PRIORITY</Tag>
              <span>{text}</span>
            </>
          );
        }
        if (record.isNew) {
          return (
            <>
              <Tag color="red">NEW</Tag>
              <span>{text}</span>
            </>
          );
        }
        if (record.isPriority) {
          return (
            <>
              <Tag color="green">PRIORITY</Tag>
              <span>{text}</span>
            </>
          );
        }
        return <span>{capitalizeFirstLetter(record.debtorName || record.companyName)}</span>;
      },
      sorter: {
        fields: ["debtor__debtor_contact__first_name"],
        compare: (a, b) => {
          const aName = a.debtorName || a.companyName;
          const bName = b.debtorName || b.companyName;

          if (aName < bName) {
            return -1;
          }
          if (aName > bName) {
            return 1;
          }
          return 0;
        },
      },
    },
    {
      key: TABLE_COLUMNS.ACCOUNT_WORKLIST__COLLECTOR_NAME,
      title: "Collector Name",
      visible: false,
      width: 150,
      render: (text, record) => {
        // NOTE: Currently collector names aren't supported by Appearances, bc backend is currently only supporting
        // agency routes to fetch Collectors. We additionally specifically mark `visible` as false for this column.
        // capitalizeFirstLetter(
        //   collectorFullName(collectors?.find((x) => x.id === record.assigneeId)),
        // ),
      },
    },
    {
      key: TABLE_COLUMNS.ACCOUNT_WORKLIST__LEGAL_STATUS,
      title: "Legal Status",
      visible: true,
      width: 150,
      dataIndex: "legalStatus",
      render: (text, record) => {
        if (record.legalStatus === "judgment") {
          return <Tag color="green">JUDGMENT APPLIED</Tag>;
        }
        if (record.legalStatus === "judgment_applied") {
          return <Tag color="green">JUDGMENT APPLIED</Tag>;
        }
        if (record.legalStatus === "in_litigation") {
          return <Tag color="orange">IN LITIGATION</Tag>;
        }
        return "-";
      },
    },
    {
      key: TABLE_COLUMNS.ACCOUNT_WORKLIST__STATUS,
      title: "Status",
      visible: true,
      width: 150,
      dataIndex: "statusName",
      render: (text, record) => {
        if (!text || !record.statusCode) {
          return "None";
        }
        return `${record.statusCode} - ${text}`;
      },
      sorter: {
        fields: ["workflow_task__state__code"],
        compare: (a, b) => {
          const aName = a.statusCode;
          const bName = b.statusCode;

          if (aName < bName) {
            return -1;
          }
          if (aName > bName) {
            return 1;
          }
          return 0;
        },
      },
    },
    {
      key: TABLE_COLUMNS.ACCOUNT_WORKLIST__CURRENT_BALANCE,
      title: "Current Balance",
      visible: true,
      width: 300,
      children: [
        {
          title: "Original Amount",
          dataIndex: "originalTotal",
          render: (text) => formatCurrency(text),
          width: 150,
        },
        {
          title: "Total Balance",
          dataIndex: "totalBalance",
          render: (text, record) => formatCurrency(record.totalBalance),
          width: 150,
        },
      ],
    },
    {
      key: TABLE_COLUMNS.ACCOUNT_WORKLIST__TURNOVER_DATE,
      title: "Turnover Date",
      visible: true,
      width: 150,
      dataIndex: "turnoverDate",
      render: (text) => formatDate(text),
      sorter: {
        compare: (a, b) => {
          if (a.turnoverDate < b.turnoverDate) {
            return -1;
          }
          if (a.turnoverDate > b.turnoverDate) {
            return 1;
          }
          return 0;
        },
      },
    },
    {
      key: TABLE_COLUMNS.ACCOUNT_WORKLIST__CLIENT,
      title: "Client",
      visible: true,
      width: 400,
      render: (text, record) => {
        const creditor = creditorIdToCreditorMap[record.creditorId];
        if (creditor?.code) {
          return `${creditor?.name} (${creditor?.code})`;
        }
        return creditor?.name ?? "-";
      },
      sorter: {
        fields: ["creditor__name", "creditor__code"],
        compare: (a, b) => {
          const aName = creditorIdToCreditorMap[a.creditorId]?.name;
          const bName = creditorIdToCreditorMap[b.creditorId]?.name;

          if (aName < bName) {
            return -1;
          }
          if (aName > bName) {
            return 1;
          }
          return 0;
        },
      },
    },
    {
      key: TABLE_COLUMNS.ACCOUNT_WORKLIST__DEBT_TYPE,
      title: "Debt Type",
      visible: true,
      width: 150,
      render: (text, _) => capitalizeFirstLetter(text),
      dataIndex: "collectionDebtType",
    },
    {
      key: TABLE_COLUMNS.ACCOUNT_WORKLIST__EXTERNAL_SCORE,
      title: "Score",
      visible: true,
      width: 150,
      sorter: {
        compare: (a, b) => parseFloat(a.score) - parseFloat(b.score),
      },
      dataIndex: "externalScore",
    },
    {
      key: TABLE_COLUMNS.ACCOUNT_WORKLIST__LAST_PAYMENT_DATE,
      title: "Last Payment Date",
      visible: true,
      width: 200,
      dataIndex: "lastPaymentDate",
      render: (text, record) => formatDate(record.lastPaymentDate) ?? "-",
      sorter: {
        compare: (a, b) => {
          if (a.lastPaymentDate < b.lastPaymentDate) {
            return -1;
          }
          if (a.lastPaymentDate > b.lastPaymentDate) {
            return 1;
          }
          return 0;
        },
      },
    },
    {
      key: TABLE_COLUMNS.ACCOUNT_WORKLIST__LAST_LETTER_SENT_DATE,
      title: "Last Letter Sent Date",
      visible: true,
      width: 200,
      dataIndex: "latestLetterSentAt",
      render: (text, record) => formatDate(record.latestLetterSentAt) ?? "-",
    },
    {
      key: TABLE_COLUMNS.ACCOUNT_WORKLIST__LATEST_LETTER_SENT_NAME,
      title: "Last Letter Sent Name",
      visible: false,
      width: 200,
      dataIndex: "latestLetterSentName",
      render: (text, record) => capitalizeFirstLetter(record.latestLetterSentName) ?? "-",
    },
    {
      key: TABLE_COLUMNS.ACCOUNT_WORKLIST__NUMBER_OF_LETTER_SENT_TASKS,
      title: "Number of Letters Sent",
      visible: false,
      width: 150,
      dataIndex: "numberOfLetterSentTasks",
      render: (text, record) => record.numberOfLetterSentTasks ?? "-",
    },
    {
      key: TABLE_COLUMNS.ACCOUNT_WORKLIST__LATEST_CALL_DATETIME,
      title: "Last Call Date",
      visible: false,
      width: 200,
      dataIndex: "latestCallDatetime",
      render: (text, record) => formatDate(record.latestCallDatetime) ?? "-",
    },
    {
      key: TABLE_COLUMNS.ACCOUNT_WORKLIST__NUMBER_OF_CALLS,
      title: "Number of Calls",
      visible: false,
      width: 150,
      dataIndex: "numberOfCalls",
      render: (text, record) => record.numberOfCalls ?? "-",
    },
    {
      key: TABLE_COLUMNS.ACCOUNT_WORKLIST__JUDGMENT_DATE,
      title: "Judgment Date",
      visible: true,
      width: 150,
      dataIndex: "judgmentDate",
      render: (text, record) => formatDate(record.judgmentDate) ?? "-",
    },
    // NOTE: Currently UDFs aren't supported by Appearances, bc backend is currently only supporting
    // agency routes.

    // ...(importantAccountUdfCustomFields?.map((udf) => ({
    //   title: udf.name,
    //   dataIndex: udf.slug,
    //   key: udf.slug,
    //   render: (text, record) => {
    //     return record.customFields?.[snakeToCamelCase(udf.slug)] || "-";
    //   },
    //   width: 150,
    //   // Currently, sorting by custom fields does not work as expected because our current pagination style
    //   // (cursor pagination) require a unique field to sort by, and custom fields are not unique.
    //   // TODO: Undo below comment when we switch the pagination
    //   // sorter: {
    //   //   fields: [`custom_fields__${udf.slug}`],
    //   // },
    // })) ?? []),
    // ...(importantDebtorUdfCustomFields?.map((udf) => ({
    //   title: udf.name,
    //   dataIndex: udf.slug,
    //   key: udf.slug,
    //   render: (text, record) =>
    //     record.debtors
    //       ?.map((debtor) => debtor?.customFields?.[snakeToCamelCase(udf.slug)])
    //       ?.filter((x) => x !== null && x !== undefined)
    //       ?.join(",") || "-",
    //   width: 150,
    // Currently, sorting by custom fields does not work as expected because our current pagination style
    // (cursor pagination) require a unique field to sort by, and custom fields are not unique.
    // TODO: Undo below comment when we switch the pagination
    // sorter: {
    //   fields: [`debtor__custom_fields__${udf.slug}`],
    // },
    // })) ?? []),
  ];

  const reconciledColumns = reconcileColumns(defaultColumns, appearance?.items?.[TABLE_ID]).filter(
    (column) => column.visible,
  );

  const handleTableChange = (pagination, filters, sorter) => {
    if (pagination && Object.keys(pagination).length > 0) {
      dispatch(setPagination(pagination));
    }

    if (sorter) {
      const orderBy = `${sorter.order === "descend" ? "-" : ""}${camelToSnakeCase(sorter.field)}`;
      dispatch(setOrderBy(orderBy));
    }
  };

  const onSelectedAccountChange = (_, selectedRows) => {
    dispatch(setSelectedRows(selectedRows));
  };

  /*
   * The `isBulk` param indicates whether the collector chose "bulk upload" vs "manual".
   * The `accountData` depends on whether it's a manual or bulk upload.  If it's manual, it's
   * an object holding the single account.  If it's bulk, it's an object holding CSV data and
   * metadata in it (not actual accounts).
   */
  const onSelectAddAccountsModeOk = async (isConsumerUpload) => {
    if (isConsumerUpload) {
      setAddConsumerAccountManuallyModalVisible(true);
    } else {
      setAddCommercialAccountManuallyModalVisible(true);
    }
    setAddAccountModalVisible(false);
  };

  const onSelectAddAccountsModeCancel = () => {
    setAddAccountModalVisible(false);
  };

  const rowSelection = {
    selectedRowKeys: accountsTableSlice.selectedRows.map((row) => row.key),
    onChange: onSelectedAccountChange,
    preserveSelectedRowKeys: true,
  };

  const showSelectAddAccountsModeModal = () => {
    setAddAccountModalVisible(true);
  };

  const onAddConsumerAccountManualModalOk = () => {
    setAddConsumerAccountManuallyModalVisible(false);
  };

  const onAddConsumerAccountManualModalCancel = () => {
    setAddConsumerAccountManuallyModalVisible(false);
  };

  const onAddCommercialAccountManualModalOk = () => {
    setAddCommercialAccountManuallyModalVisible(false);
  };

  const onAddCommercialAccountManualModalCancel = () => {
    setAddCommercialAccountManuallyModalVisible(false);
  };

  const gotoDebtorProfile = (record) => {
    const url = `/debtors/${record.debtorId}`;
    const newWindow = window.open(url, "_blank");
    if (newWindow) newWindow.opener = null;
  };

  const currentClient = clientSummary?.currentClient;
  const salesRep = clientSummary?.rootClient?.salesRep ?? clientSummary?.currentClient?.salesRep;

  return isAccountGetAuthorized ? (
    <div>
      <FloatButton.BackTop />
      <Row align="middle" justify="space-between">
        <HeaderContainerCol flex="auto">
          <Row align="middle">
            <StyledHeader>Accounts</StyledHeader>
            {isAccountCreateAuthorized && (
              <StyledButton
                onClick={() => showSelectAddAccountsModeModal()}
                icon={<PlusOutlined />}
                type="link"
              >
                Add Account
              </StyledButton>
            )}
          </Row>
          {(currentClient?.bestContact || currentClient?.bestAddress) && (
            <>
              <StyledValue>
                <strong>Client:</strong> {currentClient?.name || "-"}
              </StyledValue>
              <StyledDivider type="vertical" />
              <StyledValue>
                Address: {currentClient?.bestAddress?.address1},{" "}
                {currentClient?.bestAddress?.address2
                  ? `${currentClient?.bestAddress?.address2},`
                  : ""}{" "}
                {currentClient?.bestAddress?.city}, {currentClient?.bestAddress?.state}{" "}
                {currentClient?.bestAddress?.zipCode}
              </StyledValue>
              <StyledDivider type="vertical" />
              <StyledValue>
                Phone:{" "}
                {currentClient?.bestContact?.cellPhone ||
                  currentClient?.bestContact?.workPhone ||
                  "-"}
              </StyledValue>
              <StyledDivider type="vertical" />
              <StyledValue>Email: {currentClient?.bestContact?.email || "-"}</StyledValue>
            </>
          )}
          {salesRep && (
            <div>
              <StyledValue>
                <strong>Sales Rep:</strong>{" "}
                {salesRep?.firstName || salesRep?.lastName
                  ? `${salesRep?.firstName} ${salesRep?.lastName}`
                  : "--"}
              </StyledValue>
              <StyledDivider type="vertical" />
              <StyledValue>
                Phone: {salesRep?.phoneNumber || "-"}
                {salesRep?.phoneExtension ? ` Ext. ${salesRep?.phoneExtension || ""}` : ""}
              </StyledValue>
            </div>
          )}
        </HeaderContainerCol>
        <SearchWithDropdown
          // @ts-ignore
          initialValues={{ queryFieldType: "consumer_name" }}
          searchHook={useLazySearchCreditorPortalDebtorsByTypeQuery}
          searchResultGetter={(debtor, index) => ({
            label: (
              <a target="_blank" rel="noopener noreferrer" href={`/debtors/${debtor.id}`}>
                {collectorFullName(debtor)} (Debtor ID: {debtor.id}, Account IDs: [
                {debtor.accountIds.join(", ")}])
              </a>
            ),
            key: index,
          })}
          inputProps={{
            placeholder: "Debtor Search",
            title: "Search: Enter debtor ID, SSN, name, or account #...",
          }}
          selectProps={{
            loading: isConstantsLoading,
            options: constants?.debtorSearchCriteria?.map((criteria) => ({
              label: criteria.display,
              value: criteria.value,
            })) ?? [
              {
                label: "...",
                value: "consumer_name",
              },
            ],
          }}
        />
      </Row>
      <Row wrap={false}>
        <CreditorAccountsTableFilters />
        <StyledCol>
          <Row wrap={false}>
            <StyledActionContainer />
          </Row>
          {accountsTableSlice?.filteredAccounts.length === 0 ? (
            // @ts-ignore
            <StyledEmptyTable
              scroll={{ x: "max-content" }}
              columns={reconciledColumns}
              loading={isTableLoading || isTableFetching || isAppearanceLoading}
            />
          ) : (
            <StyledTable
              sticky
              bordered
              loading={isTableLoading || isTableFetching || isAppearanceLoading}
              rowSelection={rowSelection}
              pageSize={getPageSize}
              prevToken={accountsTableSlice?.resultPrevToken}
              nextToken={accountsTableSlice?.resultNextToken}
              resultCount={accountsTableSlice?.resultCount}
              virtualPage={accountsTableSlice?.virtualPage}
              setVirtualPage={handleSetVirtualPage}
              columns={reconciledColumns}
              dataSource={accountsTableSlice?.filteredAccounts}
              pagination={{
                pagination: onPaginationChange,
              }}
              onRow={(record) => ({
                onClick: (e) => {
                  const selection = window.getSelection().toString();
                  if (selection.length <= 0 && !isTableLoading) {
                    gotoDebtorProfile(record);
                  }
                },
              })}
              onChange={handleTableChange}
              // https://github.com/ant-design/ant-design/issues/16747#issuecomment-612047300
              sortDirections={["ascend", "descend", "ascend"]}
            />
          )}
        </StyledCol>
      </Row>
      {isAddAccountManualModalVisible && (
        <AddManualConsumerAccountModal
          title="Add Consumer Account"
          open={isAddAccountManualModalVisible}
          onOk={onAddConsumerAccountManualModalOk}
          onCancel={onAddConsumerAccountManualModalCancel}
        />
      )}
      {isAddCommercialAccountManuallyModalVisible && (
        <AddManualCommercialAccountModal
          title="Add Commercial Account"
          open={isAddCommercialAccountManuallyModalVisible}
          onOk={onAddCommercialAccountManualModalOk}
          onCancel={onAddCommercialAccountManualModalCancel}
        />
      )}
      <SelectAddAccountsModeModal
        title="Add Account"
        open={isAddAccountModalVisible}
        onOk={onSelectAddAccountsModeOk}
        onCancel={onSelectAddAccountsModeCancel}
      />
    </div>
  ) : null;
}

export default AccountsTable;
