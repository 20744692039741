import api, { transformMultipart, transformResponse } from "services/api";

export const debtorCreditReportsApi = api
  .enhanceEndpoints({ addTagTypes: ["DebtorCreditReports"] })
  .injectEndpoints({
    endpoints: (build) => ({
      getDebtorCreditReports: build.query({
        query: ({ debtorId }) => `/api/v1/core/debtors/${debtorId}/credit-reports/`,
        transformResponse: (response) => transformResponse(response),
        providesTags: (results) =>
          results
            ? // successful query
              [
                ...results.map(({ id }) => ({ type: "DebtorCreditReports", id })),
                { type: "DebtorCreditReports", id: "LIST" },
              ]
            : // an error occurred, but we still want to refetch this query when `{ type: 'DebtorCreditReports', id: 'LIST' }` is invalidated
              [{ type: "DebtorCreditReports", id: "LIST" }],
      }),
      getDebtorCreditReportPreview: build.query({
        query: ({ debtorId, debtorCreditReportId }) =>
          `/api/v1/core/debtors/${debtorId}/credit-reports/${debtorCreditReportId}/preview`,
        transformResponse: (response) => transformResponse(response),
        providesTags: (result) =>
          result
            ? // successful query
              [
                { type: "DebtorCreditReports", id: result.id },
                { type: "DebtorCreditReports", id: "LIST" },
              ]
            : // an error occurred, but we still want to refetch this query when `{ type: 'DebtorCreditReports', id: 'LIST' }` is invalidated
              [{ type: "DebtorCreditReports", id: "LIST" }],
      }),
      generateDebtorCreditReport: build.mutation({
        query: ({ debtorId, file, ...values }) => ({
          url: `/api/v1/core/debtors/${debtorId}/credit-reports/generate/`,
          method: "POST",
          body: transformMultipart(values, file),
        }),
        transformResponse: (response) => transformResponse(response),
        // Invalidates all DebtorCreditReports-type queries providing the `LIST` id - after all, depending of the sort order,
        invalidatesTags: [{ type: "DebtorCreditReports", id: "LIST" }],
      }),
    }),
  });

export const {
  useGetDebtorCreditReportsQuery,
  useGetDebtorCreditReportPreviewQuery,
  useGenerateDebtorCreditReportMutation,
} = debtorCreditReportsApi;
