import { DeleteOutlined, EditOutlined, PlusOutlined } from "@ant-design/icons";
import { Tooltip as AntDToolTip, Button, Card, Popconfirm, Row, Space, Table } from "antd";
import { FormWizard } from "components/formWizard";
import { PageHeader } from "components/pageHeader";
import { useFetchFeePlanRuleSetsQuery } from "features/feePlans/feePlansAPI";
import {
  useCreateForwardingEntityMutation,
  useDeleteForwardingEntityMutation,
  useFetchForwardingEntitiesQuery,
  useFetchForwardingEntityAddressesQuery,
  useUpdateForwardingEntityMutation,
} from "features/forwardingEntities/forwardingEntitiesAPI";
import AddressStep from "features/forwardingEntities/wizardSteps/addressStep";
import BaseStep from "features/forwardingEntities/wizardSteps/baseStep";
import ContactStep from "features/forwardingEntities/wizardSteps/contactStep";
import InvoiceStep from "features/forwardingEntities/wizardSteps/invoicesStep";
import { PERMISSIONS } from "features/permissions";
import useAuthorizations from "features/permissions/hooks/useAuthorizations";
import { useState } from "react";
import styled from "styled-components";

const StyledCard = styled(Card)`
  margin-top: 16px;
`;

const StyledRow = styled(Row)`
  margin-bottom: 8px;
`;

const StyledHeader = styled(PageHeader)`
  margin-bottom: 0;
`;

function ForwardingEntities() {
  const { data: forwardingEntities, isLoading } = useFetchForwardingEntitiesQuery();
  const [updateForwardingEntity] = useUpdateForwardingEntityMutation();
  const [createForwardingEntity] = useCreateForwardingEntityMutation();
  const [currentForwardingEntity, setCurrentForwardingEntity] = useState();
  const [isAgencyWizardView, setIsAgencyWizardView] = useState(false);
  const { data: feePlanRuleSets } = useFetchFeePlanRuleSetsQuery();
  const [deleteForwardingEntity] = useDeleteForwardingEntityMutation();
  const { data: forwardingEntityAddresses } = useFetchForwardingEntityAddressesQuery(
    {
      // @ts-ignore
      forwardingEntityId: currentForwardingEntity?.id,
    },
    // @ts-ignore
    { skip: !currentForwardingEntity?.id },
  );
  const {
    data: {
      [PERMISSIONS.FORWARDING_ENTITY__GET]: isForwardingEntityGetAuthorized,
      [PERMISSIONS.FORWARDING_ENTITY__CREATE]: isForwardingEntityCreateAuthorized,
      [PERMISSIONS.FORWARDING_ENTITY__UPDATE]: isForwardingEntityUpdateAuthorized,
      [PERMISSIONS.FORWARDING_ENTITY__DELETE]: isForwardingEntityDeleteAuthorized,
    },
  } = useAuthorizations();

  const initialValues = {
    invoiceConfig: {
      principalRemit: {
        agency: "net",
        creditor: "net",
      },
      feesRemit: {
        agency: "net",
        creditor: "net",
      },
      interestRemit: {
        agency: "net",
        creditor: "net",
      },
      adjustments: "net",
      billing: "net",
      credit: "credit_apply",
      consolidation: "single",
      taxRemitType: "net",
    },
  };

  const onNext = async (forwardingEntity) => {
    const result = await (currentForwardingEntity
      ? // @ts-ignore
        updateForwardingEntity({ ...forwardingEntity, id: currentForwardingEntity?.id })
      : createForwardingEntity(forwardingEntity));
    if ("data" in result) {
      setCurrentForwardingEntity(result.data);
    }
    return result;
  };

  const handleEditForwardingEntityClick = (record) => {
    setIsAgencyWizardView(true);
    setCurrentForwardingEntity(record);
  };

  const agencyTableColumns = [
    {
      title: "Agency Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Forwarding Entity Code",
      dataIndex: "code",
      key: "code",
    },
    {
      title: "Is Law Firm?",
      dataIndex: "isLawFirm",
      key: "isLawFirm",
      render: (text) => (text ? "Yes" : "No"),
    },
    {
      title: "Fee Plan Rule Set",
      dataIndex: "feePlanRuleSet",
      key: "feePlanRuleSet",
      render: (_, record) => {
        return feePlanRuleSets?.find(
          (ruleSet) => ruleSet.id === record.invoiceConfig.feePlanAssignmentRulesetId,
        )?.name;
      },
    },
    {
      title: "Parent Company",
      dataIndex: "parentCompany",
      key: "parentCompany",
      render: (_, record) =>
        forwardingEntities?.find((forwardingEntity) => forwardingEntity.id === record.parentId)
          ?.name,
    },
    {
      title: "Forwarding Entity Status",
      dataIndex: "status",
      key: "status",
    },
    {
      title: "Contact Name",
      dataIndex: ["bestContact", "name"],
      key: "contactName",
    },
    {
      title: "Contact Business Phone",
      dataIndex: ["bestContact", "workPhone"],
      key: "workPhone",
    },
    {
      title: "Contact Cell Phone",
      dataIndex: ["bestContact", "cellPhone"],
      key: "cellPhone",
    },
    {
      title: "Contact Email",
      dataIndex: ["bestContact", "email"],
      key: "email",
    },
    {
      title: "Contact Fax",
      dataIndex: ["bestContact", "fax"],
      key: "fax",
    },
    {
      title: "Contact Email",
      dataIndex: ["bestContact", "email"],
      key: "email",
    },
    {
      title: "Payment Method",
      dataIndex: ["invoiceConfig", "paymentMethodType"],
      key: "paymentMethod",
    },
    {
      title: "Actions",
      fixed: "right",
      dataIndex: "action",
      key: "action",
      hidden: !isForwardingEntityUpdateAuthorized && !isForwardingEntityDeleteAuthorized,
      render: (_, record) => (
        <Space size="middle">
          {isForwardingEntityUpdateAuthorized && (
            <AntDToolTip placement="bottom" title="Edit" key="edit">
              <EditOutlined key="edit" onClick={() => handleEditForwardingEntityClick(record)} />
            </AntDToolTip>
          )}
          {isForwardingEntityDeleteAuthorized && (
            <Popconfirm
              placement="topLeft"
              okText="Yes"
              title="Are you sure you want to delete this forwarding entity?"
              onConfirm={() => deleteForwardingEntity(record)}
            >
              <DeleteOutlined key="delete" />
            </Popconfirm>
          )}
        </Space>
      ),
    },
  ].filter((column) => !column.hidden);

  const agencySteps = [
    {
      title: "Agency Base info",
      form: <BaseStep forwardingEntities={forwardingEntities} />,
      onNext: async (data) => onNext(data),
    },
    {
      title: "Addresses",
      // @ts-ignore
      form: <AddressStep forwardingEntityId={currentForwardingEntity?.id} />,
      onNext: () => {
        const mailingAddressCounter = forwardingEntityAddresses.reduce(
          (acc, creditorAddress) => (creditorAddress.type === "mail" ? acc + 1 : acc),
          0,
        );
        if (mailingAddressCounter <= 0) {
          throw new Error("At least one mailing address is required");
        }
      },
      wrapperProps: {
        style: {
          maxWidth: "100%",
          width: "100%",
        },
      },
    },
    {
      title: "Agency Contacts",
      // @ts-ignore
      form: <ContactStep forwardingEntityId={currentForwardingEntity?.id} />,
      wrapperProps: {
        style: {
          maxWidth: "100%",
          width: "100%",
        },
      },
    },
    {
      title: "Invoices",
      form: <InvoiceStep />,
      onNext: async (data) => onNext(data),
    },
  ];

  const onCancelAgencyWizard = () => {
    setIsAgencyWizardView(false);
  };

  const addForwardingEntity = () => {
    setIsAgencyWizardView(true);
    setCurrentForwardingEntity(null);
  };

  return isForwardingEntityGetAuthorized ? (
    <>
      <StyledHeader>Forwarding Entities</StyledHeader>
      <StyledRow align="middle">
        <span>Configure forwarding entities and relationships.</span>
        {!isAgencyWizardView && isForwardingEntityCreateAuthorized ? (
          <Button onClick={addForwardingEntity} icon={<PlusOutlined />} type="link">
            Add Forwarding Entity
          </Button>
        ) : null}
      </StyledRow>
      {isAgencyWizardView ? (
        <StyledCard>
          <FormWizard
            canSkipSteps={!!currentForwardingEntity}
            steps={agencySteps}
            onCancel={onCancelAgencyWizard}
            onDone={() => setIsAgencyWizardView(false)}
            initialValues={currentForwardingEntity ?? initialValues}
          />
        </StyledCard>
      ) : (
        <Table
          loading={isLoading}
          dataSource={forwardingEntities}
          // @ts-ignore
          columns={agencyTableColumns}
          bordered
          scroll={{ x: "max-content" }}
        />
      )}
    </>
  ) : null;
}

export default ForwardingEntities;
