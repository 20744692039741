import { Button, Checkbox, Divider, Form, Input, InputNumber, Layout, Select, Space } from "antd";
import { formatCurrency } from "common/utils";
import { AktDatePicker } from "components/aktDatePicker";
import currency from "currency.js";
import { getPaymentsForPaymentPlanSchedule } from "features/payments/paymentDefaults";
import { useLazyCalculateBalanceFromScheduleQuery } from "features/payments/paymentsAPI";
import {
  getNextView,
  getPreviousView,
  selectPaymentsSlice,
  setDownPayment,
  setIsIncludedWithLastPaymentChange,
  setNumberOfPaymentsChange,
  setPaymentFrequency,
  setPaymentPlanConfiguration,
  setRecurringAmountChange,
} from "features/payments/paymentsSlice";
import { useFetchAccountSettlementPaymentRulesQuery } from "features/settlementAndPaymentRules/settlementAndPaymentRulesAPI";
import moment from "moment-timezone";
import { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import styled from "styled-components";

const StyledForm = styled(Form)`
  margin-top: 12px;
  max-width: 400px;
`;

const BottomSpacedStrong = styled(Layout.Content)`
  font-weight: bold;
  margin-bottom: 8px;
`;

const StyledDiv = styled.div`
  margin-bottom: 16px;
`;

const StyledInputNumber = styled(InputNumber)`
  width: 150px;
`;

const StyledCheckbox = styled(Checkbox)`
  white-space: nowrap;
  margin-bottom: 16px;
`;

function SetupPaymentPlanConfiguration() {
  const { debtorId } = useParams();
  const dispatch = useDispatch();
  const paymentsSlice = useSelector(selectPaymentsSlice);
  const tomorrowDate = moment().startOf("day");
  const [form] = Form.useForm();
  const { data: settlementPaymentRules } = useFetchAccountSettlementPaymentRulesQuery(
    {
      accountIds: paymentsSlice.selectedAccounts,
    },
    {
      skip: !paymentsSlice.selectedAccounts || paymentsSlice.selectedAccounts.length === 0,
    },
  );
  const [calculateBalanceFromSchedule, { isLoading: isCalculatingBalance }] =
    useLazyCalculateBalanceFromScheduleQuery();

  const minimumPaymentAmount = useMemo(
    () =>
      settlementPaymentRules?.reduce((max, rule) => {
        if (!rule.paymentRule?.minimumPaymentAmount) {
          return max;
        }
        const amount = currency(rule.paymentRule.minimumPaymentAmount, { precision: 4 }).value;
        return Math.max(max, amount);
      }, 0),
    [settlementPaymentRules],
  );

  const maximumPlanPaymentCount = useMemo(
    () =>
      settlementPaymentRules?.reduce((min, rule) => {
        if (!rule.paymentRule?.maximumPlanPaymentCount) {
          return min;
        }
        return Math.min(min, rule.paymentRule.maximumPlanPaymentCount);
      }, Infinity),
    [settlementPaymentRules],
  );

  const handleBalloonPayment = (amountToDistribute, numberOfPayments, recurringAmount) => {
    amountToDistribute = currency(amountToDistribute, { precision: 4 });
    numberOfPayments = currency(numberOfPayments, { precision: 4 });
    recurringAmount = currency(recurringAmount, { precision: 4 });
    const balloonPaymentAmount = amountToDistribute.subtract(
      numberOfPayments.multiply(recurringAmount),
    );
    return balloonPaymentAmount.value;
  };

  const handleRecurringAmount = (
    amountToDistribute,
    numberOfPayments,
    isIncludedWithLastPayment,
  ) => {
    let recurringAmount;

    if (!numberOfPayments) {
      numberOfPayments = 1;
      form.setFieldValue("numberOfPayments", numberOfPayments);
      form.validateFields(["numberOfPayments"]);
    }

    if (isIncludedWithLastPayment) {
      const remainder = amountToDistribute % numberOfPayments;
      amountToDistribute = currency(amountToDistribute, { precision: 4 });
      const wholeRecurringAmount = amountToDistribute.subtract(remainder);
      recurringAmount = wholeRecurringAmount.divide(numberOfPayments).value;
    } else {
      amountToDistribute = currency(amountToDistribute, { precision: 4 }).subtract(
        minimumPaymentAmount,
      );
      const remainder = amountToDistribute.value % numberOfPayments;
      const wholeRecurringAmount = amountToDistribute.subtract(remainder);
      recurringAmount = wholeRecurringAmount.divide(numberOfPayments).value;
    }
    // NOTE: One might assume that form.setFieldsValue would cause an infinite loop here,
    // but it does not.
    // E.g if 'onNumberOfPaymentsChange' is first called, it will change the
    // recurringAmount input box. However, thankfully this change does not result
    // in another call to 'onChangeRecurringAmount'.
    form.setFieldsValue({ recurringAmount });
    return recurringAmount;
  };

  const onDownPaymentChange = (downPayment) => {
    const numberOfPayments = form.getFieldValue("numberOfPayments");
    let isIncludedWithLastPayment = form.getFieldValue("isIncludedWithLastPayment");
    const amountToDistribute = currency(paymentsSlice.totalAmount, { precision: 4 }).subtract(
      downPayment,
    ).value;
    const recurringAmount = handleRecurringAmount(
      amountToDistribute,
      numberOfPayments,
      isIncludedWithLastPayment,
    );
    const balloonPayment = handleBalloonPayment(
      amountToDistribute,
      numberOfPayments,
      recurringAmount,
    );
    if (numberOfPayments !== null) {
      form.setFieldsValue({ recurringAmount, balloonPayment });
      if (balloonPayment === 0) {
        form.setFieldsValue({ isIncludedWithLastPayment: true });
        isIncludedWithLastPayment = true;
      }
    }
    dispatch(
      setDownPayment({
        downPayment,
        recurringAmount,
        balloonPayment,
        isIncludedWithLastPayment,
      }),
    );
  };

  const handleNumberOfPayments = (amountToDistribute, recurringAmount) => {
    amountToDistribute = currency(amountToDistribute, { precision: 4 });
    const numberOfPayments = Math.floor(amountToDistribute.divide(recurringAmount).value);
    return numberOfPayments;
  };

  const onChangeRecurringAmount = (newRecurringAmount) => {
    const { downPayment } = paymentsSlice;
    let isIncludedWithLastPayment = form.getFieldValue("isIncludedWithLastPayment");
    const amountToDistribute = currency(paymentsSlice.totalAmount, { precision: 4 }).subtract(
      downPayment,
    ).value;
    const numberOfPayments = handleNumberOfPayments(amountToDistribute, newRecurringAmount);
    const balloonPayment = handleBalloonPayment(
      amountToDistribute,
      numberOfPayments,
      newRecurringAmount,
    );
    if (newRecurringAmount !== null) {
      form.setFieldsValue({ numberOfPayments, balloonPayment });
      if (balloonPayment === 0) {
        form.setFieldsValue({ isIncludedWithLastPayment: true });
        isIncludedWithLastPayment = true;
      }
    }
    dispatch(
      setRecurringAmountChange({
        recurringAmount: newRecurringAmount,
        numberOfPayments,
        balloonPayment,
        isIncludedWithLastPayment,
      }),
    );
  };

  const onNumberOfPaymentsChange = (newNumberOfPayments) => {
    if (!newNumberOfPayments) {
      return null;
    }
    const { downPayment } = paymentsSlice;
    let isIncludedWithLastPayment = form.getFieldValue("isIncludedWithLastPayment");
    const amountToDistribute = currency(paymentsSlice.totalAmount, { precision: 4 }).subtract(
      downPayment,
    ).value;
    const recurringAmount = handleRecurringAmount(
      amountToDistribute,
      newNumberOfPayments,
      isIncludedWithLastPayment,
    );
    const balloonPayment = handleBalloonPayment(
      amountToDistribute,
      newNumberOfPayments,
      recurringAmount,
    );
    if (newNumberOfPayments !== null) {
      form.setFieldsValue({ recurringAmount, balloonPayment });
      if (balloonPayment === 0) {
        form.setFieldsValue({ isIncludedWithLastPayment: true });
        isIncludedWithLastPayment = true;
      }
    }
    dispatch(
      setNumberOfPaymentsChange({
        numberOfPayments: newNumberOfPayments,
        recurringAmount,
        balloonPayment,
        isIncludedWithLastPayment,
      }),
    );
  };

  const onPaymentFrequencyChange = (val) => {
    dispatch(setPaymentFrequency(val));
  };

  const onCheckboxChange = (e) => {
    dispatch(setIsIncludedWithLastPaymentChange(e.target.checked));
    const numberOfPayments = form.getFieldValue("numberOfPayments");
    const downPayment = form.getFieldValue("downPayment");
    const isIncludedWithLastPayment = e.target.checked;

    const amountToDistribute = currency(paymentsSlice.totalAmount, { precision: 4 }).subtract(
      downPayment,
    ).value;
    const recurringAmount = handleRecurringAmount(
      amountToDistribute,
      numberOfPayments,
      isIncludedWithLastPayment,
    );
    const balloonPayment = handleBalloonPayment(
      amountToDistribute,
      numberOfPayments,
      recurringAmount,
    );
    form.setFieldsValue({ recurringAmount, balloonPayment });
  };

  const onFinish = async (values) => {
    const {
      downPayment,
      recurringAmount,
      numberOfPayments,
      startDate,
      frequency,
      isIncludedWithLastPayment,
      balloonPayment,
    } = values;
    const paymentsSchedule = getPaymentsForPaymentPlanSchedule({
      accountIds: paymentsSlice.selectedAccounts,
      downPayment,
      recurringAmount,
      balloonPayment,
      numberOfPayments,
      startDate,
      frequency,
      isIncludedWithLastPayment,
    });

    // We will calculate the amount for the last payment on the backend
    // Only applies for full payment amount and payment plans
    if (paymentsSlice.isPaymentPlan && paymentsSlice.paymentIntentType === "full") {
      const intents = paymentsSchedule.map((payment) => ({
        ...payment,
        totalAmount: payment.amount ?? 0,
        scheduledDate: payment.date,
      }));

      const result = await calculateBalanceFromSchedule({
        debtorId,
        intents,
      });

      if ("error" in result || !result.data) {
        return;
      }

      // The last payment will include 'accrued interest' depending on the Payment Schedule
      // We need to extract the last payment total and add it to the last payment
      paymentsSchedule[paymentsSchedule.length - 1].amount = currency(
        paymentsSchedule[paymentsSchedule.length - 1].amount,
        { precision: 4 },
      ).add(result.data.summary[result.data.summary.length - 1].remainingBalanceTotal).value;
    }

    dispatch(
      setPaymentPlanConfiguration({
        downPayment,
        recurringAmount,
        numberOfPayments,
        startDateTimeStamp: startDate,
        frequency,
        paymentsSchedule,
      }),
    );
    dispatch(getNextView());
  };

  const disabledDate = (current) => {
    // Can not select days before tomorrow, as today should only be the down payment.
    return current && current < tomorrowDate;
  };

  const maxNumberOfInstallments = useMemo(
    () =>
      maximumPlanPaymentCount -
      (!paymentsSlice.isIncludedWithLastPayment ? 1 : 0) -
      (paymentsSlice.downPayment === 0 ||
      paymentsSlice.downPayment === undefined ||
      paymentsSlice.downPayment === null
        ? 0
        : 1),
    [maximumPlanPaymentCount, paymentsSlice.downPayment, paymentsSlice.isIncludedWithLastPayment],
  );

  return (
    <>
      <BottomSpacedStrong>Setup a Payment Plan</BottomSpacedStrong>
      <StyledForm
        layout="vertical"
        form={form}
        initialValues={{
          downPayment: paymentsSlice.downPayment,
          amount: paymentsSlice.totalAmount,
          recurringAmount: paymentsSlice.recurringAmount ?? paymentsSlice.totalAmount,
          numberOfPayments: paymentsSlice.numberOfPayments,
          startDate: tomorrowDate,
          frequency: paymentsSlice.paymentFrequency,
          isIncludedWithLastPayment: paymentsSlice.isIncludedWithLastPayment,
          balloonPayment: paymentsSlice.balloonPayment,
        }}
        validateMessages={{ required: "This is a required field" }}
        onFinish={onFinish}
      >
        <Form.Item
          name="downPayment"
          label="Down Payment"
          rules={[
            { required: false },
            {
              validator: (_, value) => {
                // If the down payment is not set, we can proceed.
                if (value === undefined || value === null) {
                  return Promise.resolve(true);
                }
                if (value === 0) {
                  return Promise.reject(new Error("The entered amount must be greater than 0"));
                }
                if (value < minimumPaymentAmount) {
                  return Promise.reject(
                    new Error(`Down payment must be greater than $${minimumPaymentAmount}`),
                  );
                }
                return Promise.resolve(true);
              },
            },
          ]}
          validateTrigger="onBlur" // Setting Form.Item’s prop validateTrigger="onBlur" ensures that validation only happens when the field loses the focus.
        >
          <StyledInputNumber prefix="$" controls={false} min={0} onChange={onDownPaymentChange} />
        </Form.Item>
        <Form.Item label="Payment Frequency" rules={[{ required: true }]} required>
          <Input.Group compact>
            <Form.Item
              noStyle
              name="numberOfPayments"
              label="Number of Payments"
              rules={[
                { required: true },
                {
                  type: "number",
                  max: maxNumberOfInstallments,
                  message: `Number of payments must be less than or equal to ${maxNumberOfInstallments}`,
                },
              ]}
            >
              <StyledInputNumber
                placeholder="Enter a number..."
                onChange={onNumberOfPaymentsChange}
                controls={false}
              />
            </Form.Item>
            <Form.Item noStyle name="frequency" label="Frequency" rules={[{ required: true }]}>
              <Select
                options={[
                  { label: "Monthly", value: "monthly" },
                  { label: "Bi-monthly", value: "bimonthly" },
                  { label: "Weekly", value: "weekly" },
                  { label: "Bi-weekly", value: "biweekly" },
                ]}
                popupMatchSelectWidth={false}
                onChange={onPaymentFrequencyChange}
              />
            </Form.Item>
          </Input.Group>
        </Form.Item>
        <Form.Item
          name="recurringAmount"
          label="Recurring Amount"
          rules={[
            { required: true },
            {
              validator: (_, value) => {
                if (value <= 0) {
                  return Promise.reject(new Error("The entered amount must be greater than 0"));
                }
                if (value < minimumPaymentAmount) {
                  return Promise.reject(
                    new Error(`Recurring amount must be greater than $${minimumPaymentAmount}`),
                  );
                }
                return Promise.resolve(true);
              },
            },
          ]}
          required
        >
          <StyledInputNumber
            onChange={onChangeRecurringAmount}
            prefix="$"
            precision={2}
            controls={false}
            min={0}
          />
        </Form.Item>
        {paymentsSlice.paymentIntentType === "full" ? (
          <Form.Item noStyle name="isIncludedWithLastPayment" valuePropName="checked" hidden>
            <StyledCheckbox
            /* disabled={balloonPayment === 0} onChange={onCheckboxChange} */
            >
              Included with last payment
            </StyledCheckbox>
          </Form.Item>
        ) : (
          <Space>
            <Form.Item
              name="balloonPayment"
              rules={[
                { required: true },
                {
                  validator: (_, value) => {
                    if (value < minimumPaymentAmount && !paymentsSlice.isIncludedWithLastPayment) {
                      return Promise.reject(
                        new Error(`Remaining amount must be greater than $${minimumPaymentAmount}`),
                      );
                    }
                    return Promise.resolve(true);
                  },
                },
              ]}
            >
              <StyledInputNumber prefix="$" precision={2} controls={false} min={0} disabled />
            </Form.Item>
            <Form.Item
              shouldUpdate={(prevValues, currentValues) =>
                prevValues.balloonPayment !== currentValues.balloonPayment
              }
            >
              {({ getFieldValue }) => {
                const balloonPayment = getFieldValue("balloonPayment");
                return (
                  <Form.Item noStyle name="isIncludedWithLastPayment" valuePropName="checked">
                    <StyledCheckbox disabled={balloonPayment === 0} onChange={onCheckboxChange}>
                      Included with last payment
                    </StyledCheckbox>
                  </Form.Item>
                );
              }}
            </Form.Item>
          </Space>
        )}
        <Form.Item name="startDate" label="Payment Start Date" rules={[{ required: true }]}>
          <AktDatePicker disabledDate={disabledDate} />
        </Form.Item>
        <Divider />
        {paymentsSlice.paymentIntentType === "full" ? (
          <StyledDiv>
            <span>Amount Due Across Selected Accounts Prior To Interest: </span>
            <strong>{`${formatCurrency(paymentsSlice.originalTotalAmount)}`}</strong>
          </StyledDiv>
        ) : (
          <StyledDiv>
            <span>Amount Due Across Selected Accounts: </span>
            <strong>{`${formatCurrency(paymentsSlice.originalTotalAmount)}`}</strong>
            <div>
              <span>Payment Amount: </span>
              <strong>{formatCurrency(paymentsSlice.totalAmount)}</strong>
            </div>
          </StyledDiv>
        )}
        <Form.Item>
          <Space>
            <Button
              onClick={() => {
                dispatch(getPreviousView());
              }}
            >
              Back
            </Button>
            <Button
              type="primary"
              htmlType="submit"
              disabled={!paymentsSlice.numberOfPayments || !paymentsSlice.recurringAmount}
              loading={isCalculatingBalance}
            >
              Preview Plan
            </Button>
          </Space>
        </Form.Item>
      </StyledForm>
    </>
  );
}

export default SetupPaymentPlanConfiguration;
