import { Modal, Button, Skeleton } from "antd";
import styled from "styled-components";
import DOMPurify from "dompurify";
import { useGetDebtorCreditReportPreviewQuery } from "features/debtorCreditReportTable/debtorCreditReportAPI";

const StyledContainer = styled.div`
  padding: 20px;
  overflow: auto;
  border: 1px solid #ccc;
  margin-top: 10px;
  border-radius: 3px;
  height: calc(70vh - 50px); // Adjusts height dynamically
`;

const StyledContentPreview = styled.div`
  margin: 40 auto;
  padding-left: 40px;
  width: 9.5in;
  overflow: auto;
  justify-content: center; // Center horizontally
`;

function ViewDebtorCreditReportModal({ title, open, record, onOk, onCancel }) {
  const { data: previewData, isLoading: isDebtorCreditReportPreviewLoading } =
    useGetDebtorCreditReportPreviewQuery(
      {
        debtorId: record?.debtorId,
        debtorCreditReportId: record?.id,
      },
      {
        skip: !record,
      },
    );

  return (
    <Modal
      title={title}
      open={open}
      onCancel={onCancel}
      width="1100px" // Responsive width with margins
      footer={[
        <Button key="close" onClick={onCancel}>
          Close
        </Button>,
      ]}
    >
      {isDebtorCreditReportPreviewLoading ? (
        <Skeleton active />
      ) : (
        <StyledContainer>
          <StyledContentPreview>
            <div
              dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(previewData?.htmlContent) }}
            />
          </StyledContentPreview>
        </StyledContainer>
      )}
    </Modal>
  );
}

export default ViewDebtorCreditReportModal;
