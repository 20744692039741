import { Form, Input, InputNumber } from "antd";
import { snakeToCamelCase, udfToComponentMap } from "common/utils";
import { AktDatePicker } from "components/aktDatePicker";
import { AktPercentageInput } from "components/aktPercentageInput";
import { useUserType } from "features/auth";
import { useFetchAllBackupDatesQuery } from "features/backupDates/backupDatesAPI";
import { useFetchCreditorQuery } from "features/creditors/agencyPortal/creditorsAPI";
import { useGetCreditorPortalCreditorQuery } from "features/creditors/creditorPortal/creditorsAPI";
import { useFetchBackendConstantsQuery } from "features/home/agencyPortal/homeAPI";
import { useFetchAllAccountUdfCustomFieldsQuery } from "features/userDefinedFields/accountUserDefinedFieldsAPI";
import { useMemo } from "react";

function AccountConsumerStep({ creditorId }) {
  const form = Form.useFormInstance();
  const { isAgencyUserType, isCreditorUserType } = useUserType();
  const { data: constants } = useFetchBackendConstantsQuery();
  const { data: backupDates } = useFetchAllBackupDatesQuery();

  const { data: agencySelectedCreditor } = useFetchCreditorQuery(
    { creditorId },
    { skip: !creditorId || isCreditorUserType },
  );
  const { data: creditorSelectedCreditor } = useGetCreditorPortalCreditorQuery(
    { creditorId },
    { skip: !creditorId || isAgencyUserType },
  );
  const { data: accountCustomFields } = useFetchAllAccountUdfCustomFieldsQuery();
  const selectedCreditor = agencySelectedCreditor || creditorSelectedCreditor;
  const creditorOptions = useMemo(() => {
    if (constants && selectedCreditor) {
      return constants?.paymentCategories?.invoiceable.filter((each) =>
        selectedCreditor.paymentConfig.paymentCategoryPriority.includes(each.value),
      );
    }
  }, [constants, selectedCreditor]);

  function isFieldRequiredByBackupDates(field) {
    /**
     * Check if one of the forms fields is required according to the backup dates information
     */
    if (!backupDates) return false;
    for (let i = 0; i < backupDates.length; i++) {
      const element = backupDates[i];
      if (snakeToCamelCase(element.systemDateField) === field && element.isRequired) {
        return true;
      }
    }
    return false;
  }

  return (
    <>
      <Form.Item label="Client Reference ID" name="clientReferenceId">
        <Input />
      </Form.Item>
      <Form.Item
        label="Interest Rate"
        name="interestRate"
        tooltip="Interest is accumulated as annual APR, not compounded interest."
      >
        <AktPercentageInput />
      </Form.Item>
      <Form.Item
        label="Interest Calculation Date"
        name="interestStartDate"
        tooltip="Interest accumulates as annual APR, not compound interest, calculated off the principal starting from the interest calculation date."
      >
        <AktDatePicker />
      </Form.Item>
      <Form.Item
        label="Date of Service"
        name="dateOfService"
        tooltip="The date the service was performed."
        rules={[
          {
            message: "This field is required.",
            required: isFieldRequiredByBackupDates("dateOfService"),
          },
        ]}
      >
        <AktDatePicker />
      </Form.Item>
      <Form.Item
        label="Itemization Date"
        name="itemizationDate"
        rules={[
          {
            message: "This field is required.",
            required: isFieldRequiredByBackupDates("itemizationDate"),
          },
        ]}
      >
        <AktDatePicker />
      </Form.Item>
      <Form.Item
        label="Turnover Date"
        name="turnoverDate"
        rules={[
          {
            message: "This field is required.",
            required: isFieldRequiredByBackupDates("turnoverDate"),
          },
        ]}
      >
        <AktDatePicker />
      </Form.Item>
      <Form.Item
        label="Date of First Delinquency"
        name="dateOfFirstDelinquency"
        rules={[
          {
            message: "This field is required.",
            required: isFieldRequiredByBackupDates("dateOfFirstDelinquency"),
          },
        ]}
      >
        <AktDatePicker />
      </Form.Item>
      <Form.Item label="Judgment Date" name="judgmentDate">
        <AktDatePicker />
      </Form.Item>
      <Form.Item
        label="Last Statement Date"
        name="lastStatementDate"
        rules={[
          {
            message: "This field is required.",
            required: isFieldRequiredByBackupDates("lastStatementDate"),
          },
        ]}
      >
        <AktDatePicker />
      </Form.Item>
      <Form.Item
        label="Charge-off Date"
        name="chargeOffDate"
        rules={[
          {
            message: "This field is required.",
            required: isFieldRequiredByBackupDates("chargeOffDate"),
          },
        ]}
      >
        <AktDatePicker />
      </Form.Item>
      <Form.Item
        label="[NEW] - Total Previous Payments & Credits"
        name="totalPreviousCreditsAtTurnover"
        tooltip='The total amount of all previous payments and credits prior to the turnover date including the "Last Payment Amount". This field will be subtracted from the total original balance, and pay towards the principal payment amount so must be strictly less than the original principal balance. If this field is used in combination with "Last Payment Amount", the "Last Payment Amount" will only be subtracted from this field so as to not account for the last payment twice towards the balance.'
        rules={[
          () => ({
            message:
              'This field must be less than the "Original Principal". Please see the tooltip for more information.',
            validator(rule, value) {
              const principal = form.getFieldValue("principal");
              if (value && value >= principal) {
                return Promise.reject(new Error());
              }
              return Promise.resolve();
            },
          }),
        ]}
      >
        <InputNumber prefix="$" min={0} step={0.01} />
      </Form.Item>
      <Form.Item
        label="[NEW] - Last Payment Amount"
        name="lastPaymentAmountAtTurnover"
        tooltip="The last payment amount paid by the consumer prior to the turnover date."
      >
        <InputNumber prefix="$" min={0} step={0.01} />
      </Form.Item>
      <Form.Item
        label="Last Payment Date"
        name="lastPaymentDateAtTurnover"
        tooltip="The date of the last payment made by the consumer prior to the turnover date."
        rules={[
          () => ({
            message:
              'The "Last Payment Date" must be filled in when the "Last Payment Amount" is specified.',
            validator(rule, value) {
              /* 
                Note: null >= 0 will return true so we need extra null checks
                If Last Payment Date is specified and:
                  - Payment Amount = null, then ERROR (“total previous payment amount must be specified if last payment date is specified”)
                  - Payment Amount = 0, then VALID and create a legacy payment
                  - Payment Amount > 0, then VALID and create a legacy payment
                If Last Payment Date is NOT specified and:
                  - Payment Amount = null, then VALID and do NOT create a legacy payment
                  - Payment Amount = 0, then ERROR (“last payment date must be specified if total previous payment amount is specified”)
                  - Payment Amount > 0, then ERROR (“last payment date must be specified if total previous payment amount is specified”)
              */
              const lastPaymentDateAtTurnover = value;
              const lastPaymentAmountAtTurnover = form.getFieldValue("lastPaymentAmountAtTurnover");
              if (
                (lastPaymentDateAtTurnover === null && lastPaymentAmountAtTurnover) ||
                (!lastPaymentDateAtTurnover &&
                  lastPaymentAmountAtTurnover !== null &&
                  lastPaymentAmountAtTurnover >= 0)
              ) {
                return Promise.reject(new Error());
              }

              return Promise.resolve();
            },
          }),
          () => ({
            message: "This field is required.",
            validator(rule, value) {
              // NOTE: During account upload, only the 'last_payment_date_at_turnover' and 'last_payment_amount_at_turnover' fields are allowed
              // and are used to create a legacy payment.
              // 'last_payment_date' and 'last_payment_amount' are fields derived from these, and will continue to change as payments are made in Aktos
              // after the account is uploaded.
              // Keep in mind that the former has the suffix 'at_turnover' and the latter does not.
              // Here, 'last_payment_date' is specified, because the backend constants is named that way (see AccountDateField).
              if (!value && isFieldRequiredByBackupDates("lastPaymentDate")) {
                return Promise.reject(new Error());
              }
              return Promise.resolve();
            },
          }),
        ]}
      >
        <AktDatePicker />
      </Form.Item>
      {creditorOptions?.map((category) => (
        <Form.Item
          label={`Original ${category.display}`}
          name={category.value}
          key={category.value}
          rules={[
            {
              required: constants?.paymentCategories?.requiredCategories.includes(category.value),
            },
          ]}
        >
          <InputNumber prefix="$" min={0} step={0.01} />
        </Form.Item>
      ))}
      {accountCustomFields?.map((field) => (
        <Form.Item
          label={field.name}
          name={["customFields", `${snakeToCamelCase(field.slug)}`]}
          key={field.id}
          rules={[{ required: field.isRequired }]}
        >
          {udfToComponentMap[field.type]}
        </Form.Item>
      ))}
    </>
  );
}

export default AccountConsumerStep;
