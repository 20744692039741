import { Divider, Layout, Radio, message } from "antd";
import { DATE_FORMAT } from "common/constants";
import { formatCurrency } from "common/utils";
import { useUserType } from "features/auth";
import CreatePaymentMethodForm from "features/payments/components/createPaymentMethodForm";
import SelectExistingPaymentMethodForm from "features/payments/components/selectExistingPaymentMethodForm";
import { useCreateCreditorPortalPaymentIntentsMutation } from "features/payments/creditorPortal/paymentsAPI";
import { getPaymentIntents } from "features/payments/paymentDefaults";
import {
  useCreatePaymentIntentsMutation,
  useGetPaymentMethodOptionsQuery,
} from "features/payments/paymentsAPI";
import {
  getPreviousView,
  resetMakeAPayment,
  selectPaymentsSlice,
  setIsNewPaymentMethodOption,
} from "features/payments/paymentsSlice";
import moment from "moment-timezone";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import styled from "styled-components";

const BottomSpacedStrong = styled(Layout.Content)`
  font-weight: bold;
  margin-bottom: 8px;
`;

function SelectPaymentMethod() {
  const { isAgencyUserType } = useUserType();
  const { debtorId } = useParams();
  const dispatch = useDispatch();
  const paymentsSlice = useSelector(selectPaymentsSlice);
  const [createAgencyPaymentIntents, { isLoading: agencyConfirmPaymentLoading }] =
    useCreatePaymentIntentsMutation();
  const [createCreditorPortalPaymentIntents, { isLoading: creditorPortalConfirmPaymentLoading }] =
    useCreateCreditorPortalPaymentIntentsMutation();
  const [createPaymentIntents, confirmPaymentLoading] = isAgencyUserType
    ? [createAgencyPaymentIntents, agencyConfirmPaymentLoading]
    : [createCreditorPortalPaymentIntents, creditorPortalConfirmPaymentLoading];
  const { data: paymentMethodOptions } = useGetPaymentMethodOptionsQuery(
    {
      debtorId,
    },
    { skip: !isAgencyUserType },
  );

  const onPaymentMethodCreated = async (
    paymentMethodId,
    referenceNumber,
    promiseToPayDate,
    promiseToPayLastDate,
  ) => {
    const intents = getPaymentIntents({
      paymentMethodId,
      referenceNumber,
      promiseToPayDate,
      promiseToPayLastDate,
      paymentsSchedule: paymentsSlice.paymentsSchedule,
      paymentIntentType: paymentsSlice.paymentIntentType,
      isPaymentPlan: paymentsSlice.isPaymentPlan,
    });
    const result = await createPaymentIntents({
      paymentMethodId,
      debtorId,
      intents,
    });
    if ("data" in result) {
      const successMessage = isAgencyUserType
        ? "Payment created. Please see Payment History tab for results."
        : "Payment created successfully.";
      message.success(successMessage, 3);
      await dispatch(resetMakeAPayment());
    }
  };

  const onExistingPaymentMethodSelected = async () => {
    message.success("Payment created. Please see Payment History tab for results.", 3);
    await dispatch(resetMakeAPayment());
  };

  const onSelectExistingPaymentMethodBack = () => {
    dispatch(getPreviousView());
  };

  const onPaymentMethodBack = () => {
    dispatch(getPreviousView());
  };

  const onIsPaymentMethodChange = (e) => {
    dispatch(setIsNewPaymentMethodOption(e.target.value));
  };

  let submitButtonText = "";
  if (!paymentsSlice.isPaymentPlan) {
    // One Time Payment
    submitButtonText = `Confirm Payment (${formatCurrency(paymentsSlice.totalAmount)})`;
  }
  if (paymentsSlice.downPayment) {
    // Payment plan with down payment
    submitButtonText = `Confirm Payment (${formatCurrency(paymentsSlice.downPayment)})`;
  } else if (paymentsSlice.isPaymentPlan) {
    // Payment plan without down payment
    submitButtonText = `Setup Payment Plan`;
  }

  // If this is Paid to Creditor or to a Forwarding Entity, we only show the respective
  // logging payment method, which only exists in the CreatePaymentMethodForm, since
  // the SelectExistingPaymentMethodForm only contains process_ach and process_card
  // payment method option types.
  if (!(paymentsSlice.paidTo === "agency")) {
    return (
      <CreatePaymentMethodForm
        submitButtonText={submitButtonText}
        confirmPaymentLoading={confirmPaymentLoading}
        onOk={onPaymentMethodCreated}
        onCancel={onPaymentMethodBack}
      />
    );
  }

  if (moment(paymentsSlice.paymentsSchedule[0].date).isBefore(moment().format(DATE_FORMAT))) {
    // If a payment was made in the past, we don't allow them to process the payment (Card/ACH)
    // as those can only apply to future payments. All logging payment functionality
    // exists on the CreatePaymentMethodForm.
    return (
      <CreatePaymentMethodForm
        submitButtonText={submitButtonText}
        confirmPaymentLoading={confirmPaymentLoading}
        onOk={onPaymentMethodCreated}
        onCancel={onPaymentMethodBack}
      />
    );
  }

  return (
    <>
      <BottomSpacedStrong>Payment Details</BottomSpacedStrong>
      {paymentMethodOptions?.length > 0 && (
        <>
          <Radio.Group onChange={onIsPaymentMethodChange} value={paymentsSlice.isNewPaymentMethod}>
            <Radio value>New Payment Method</Radio>
            <Radio value={false}>Existing payment Method</Radio>
          </Radio.Group>
          <Divider />
        </>
      )}
      {paymentsSlice.isNewPaymentMethod ? (
        <CreatePaymentMethodForm
          submitButtonText={submitButtonText}
          confirmPaymentLoading={confirmPaymentLoading}
          onOk={onPaymentMethodCreated}
          onCancel={onPaymentMethodBack}
        />
      ) : (
        <SelectExistingPaymentMethodForm
          onOk={onExistingPaymentMethodSelected}
          onCancel={onSelectExistingPaymentMethodBack}
        />
      )}
    </>
  );
}

export default SelectPaymentMethod;
