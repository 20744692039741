import { DeleteOutlined, EditOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Col, message, Popconfirm, Row, Space, Table } from "antd";
import { renderAddress } from "common/utils";
import { PageHeader } from "components/pageHeader";
import AddCourtOfLawModal from "features/courtsOfLawTable/components/addCourtOfLawModal";
import {
  useDeleteCourtOfLawMutation,
  useFetchCourtsOfLawQuery,
} from "features/courtsOfLawTable/courtsOfLawAPI";
import { useFetchBackendConstantsQuery } from "features/home/agencyPortal/homeAPI";
import { useState } from "react";
import styled from "styled-components";

const StyledTable = styled(Table)`
  cursor: pointer;
  margin-top: 12px;
`;

export default function CourtsOfLawTable() {
  const { data: constants } = useFetchBackendConstantsQuery();
  const [modalVisible, setModalVisible] = useState(false);
  const [activeRecord, setActiveRecord] = useState(null);
  const { data: courtsOfLaw, isLoading } = useFetchCourtsOfLawQuery();
  const [deleteCourtOfLaw] = useDeleteCourtOfLawMutation();

  const handleDelete = async (record) => {
    const result = await deleteCourtOfLaw({
      courtOfLawId: record.id,
    });

    if ("data" in result) {
      message.success("Court of law deleted successfully");
    }
  };

  const onCancel = async () => {
    setModalVisible(false);
    setActiveRecord(null);
  };

  const columns = [
    {
      title: "Court Code",
      dataIndex: "code",
    },
    {
      title: "Court Type",
      dataIndex: "type",
    },
    {
      title: "Court Name",
      dataIndex: "name",
    },
    {
      title: "Department",
      dataIndex: "department",
      render: (text) => text || "-",
    },
    {
      title: "Division",
      dataIndex: "division",
      render: (text) => text || "-",
    },
    {
      title: "Address",
      render: (text, record) => renderAddress(record, constants?.states),
    },
    {
      title: "County",
      dataIndex: "county",
      render: (text) => text || "-",
    },
    {
      title: "District",
      dataIndex: "district",
      render: (text) => text || "-",
    },
    {
      title: "Phone",
      dataIndex: "phone",
      render: (text) => text || "-",
    },
    {
      title: "Email",
      dataIndex: "email",
      render: (text) => text || "-",
    },
    {
      title: "URL",
      dataIndex: "url",
      render: (text) => text || "-",
    },
    {
      title: "Notes",
      dataIndex: "notes",
      render: (text) => text || "-",
    },
    {
      title: "Actions",
      fixed: "right",
      render: (text, record) => (
        <Space size="middle">
          <EditOutlined
            key="edit"
            onClick={() => {
              setActiveRecord(record);
              setModalVisible(true);
            }}
          />
          <Popconfirm
            placement="topLeft"
            okText="Yes"
            onConfirm={() => handleDelete(record)}
            title="Are you sure you want to delete this?"
          >
            <DeleteOutlined key="delete" />
          </Popconfirm>
        </Space>
      ),
    },
  ];

  return (
    <Col>
      <PageHeader>Courts of Law</PageHeader>
      <Row align="middle">
        <span>Configure courts of law and their locations.</span>
        <Button onClick={() => setModalVisible(true)} type="link" icon={<PlusOutlined />}>
          Add Court of Law
        </Button>
      </Row>
      <StyledTable
        loading={isLoading}
        bordered
        scroll={{ x: "max-content" }}
        columns={columns}
        dataSource={courtsOfLaw}
      />
      {modalVisible && (
        <AddCourtOfLawModal record={activeRecord} onOk={onCancel} onCancel={onCancel} />
      )}
    </Col>
  );
}
