import {
  Button,
  Divider,
  Flex,
  Form,
  Layout,
  Radio,
  Row,
  Space,
  Switch,
  Table,
  Tooltip,
} from "antd";
import { formatCurrency } from "common/utils";
import currency from "currency.js";
import { useUserType } from "features/auth";
import { useFetchCreditorSummariesQuery } from "features/creditors/agencyPortal/creditorsAPI";
import { useFetchDebtorAccountsQuery } from "features/debtorAccountsTable/agencyPortal/debtorAccountsAPI";
import { useFetchCreditorPortalDebtorAccountsQuery } from "features/debtorAccountsTable/creditorPortal/debtorAccountsAPI";
import { getPaymentsScheduleForOneTimePayment } from "features/payments/paymentDefaults";
import {
  getNextView,
  selectPaymentsSlice,
  setAsOneTimePayment,
  setAsPaymentPlan,
  setOneTimePaymentsSchedule,
  setPaidTo,
  setSelectedAccounts,
  setupAsPaymentPlan,
  setIsZeroBalanceAccountVisibleOption,
} from "features/payments/paymentsSlice";
import PaymentConfiguration from "features/payments/wizardSteps/common/paymentConfiguration";
import { PERMISSIONS, useAuthorization } from "features/permissions";
import { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import styled from "styled-components";

const BottomSpacedStrong = styled(Layout.Content)`
  font-weight: bold;
  margin-bottom: 8px;
`;

const StyledHeader = styled.h4`
  font-weight: 500;
`;

const StyledAccountsHeader = styled.h4`
  font-weight: 500;
  margin-bottom: 0;
`;

const StyledValue = styled.strong`
  font-weight: 500;
`;

const StyledTable = styled(Table)`
  margin-top: 12px;
  & .ant-table-cell:not(th) {
    padding: 4px 8px !important;
  }
`;

const StyledSwitchLabel = styled.span`
  font-size: 12px;
`;

function SelectAccountsForPayment() {
  const { isAgencyUserType, isCreditorUserType } = useUserType();
  const { debtorId } = useParams();
  const dispatch = useDispatch();
  const { data: agencyDebtorAccounts } = useFetchDebtorAccountsQuery(
    { debtorId },
    { skip: !isAgencyUserType },
  );
  const { data: creditorPortalDebtorAccounts, isLoading } =
    useFetchCreditorPortalDebtorAccountsQuery({ debtorId }, { skip: isAgencyUserType });
  const debtorAccounts = isAgencyUserType ? agencyDebtorAccounts : creditorPortalDebtorAccounts;
  const paymentsSlice = useSelector(selectPaymentsSlice);
  const { data: creditors } = useFetchCreditorSummariesQuery({}, { skip: !isAgencyUserType });
  const debtTotal = Object.values(paymentsSlice.selectedAccounts).reduce(
    (accumulator, selectedAccountId) => {
      const selectedAccount = debtorAccounts?.find(
        (debtorAccount) => debtorAccount.id === selectedAccountId,
      );
      return currency(accumulator, { precision: 4 }).add(selectedAccount?.totalBalance ?? 0);
    },
    0,
  );
  const { data: isPaymentsScheduleAuthorized } = useAuthorization(PERMISSIONS.PAYMENT__SCHEDULE);

  const columns = [
    {
      title: "Account",
      dataIndex: "id",
      render: (text, record) => {
        return <span>Account #{record.id}</span>;
      },
    },
    {
      title: "Amount Due",
      dataIndex: "debtAmount",
      render: (text) => (text !== null ? formatCurrency(text) : null),
    },
    {
      title: "Client",
      dataIndex: "creditorName",
      hidden: !isAgencyUserType,
    },
  ].filter((column) => !column.hidden);

  const { isZeroBalanceAccountVisible } = paymentsSlice;

  const data = useMemo(
    () =>
      debtorAccounts
        ?.reduce((acc, debtorAccount) => {
          const debtAmount = currency(debtorAccount.totalBalance, { precision: 4 }).value;
          const creditor = creditors?.find((c) => c.id === debtorAccount.creditorId);
          return [
            ...acc,
            {
              key: debtorAccount.id,
              id: debtorAccount.id,
              creditorName: creditor?.name,
              debtAmount,
            },
          ];
        }, [])
        .filter(({ debtAmount }) => isZeroBalanceAccountVisible || debtAmount > 0),
    [creditors, debtorAccounts, isZeroBalanceAccountVisible],
  );

  useEffect(() => {
    // Set default paidTo value based on the user type
    const paidTo = isCreditorUserType ? "creditor" : "agency";
    dispatch(setPaidTo(paidTo));

    if (data?.length > 0) {
      dispatch(
        setSelectedAccounts(
          data.filter((account) => account.debtAmount).map((account) => account.key),
        ),
      );
    }
  }, [dispatch, isCreditorUserType, data]);

  const onSelectChange = (newSelectedRowKeys) => {
    dispatch(setSelectedAccounts(newSelectedRowKeys));
  };

  const rowSelection = {
    selectedRowKeys: paymentsSlice.selectedAccounts,
    onChange: onSelectChange,
  };
  const hasSelected = paymentsSlice.selectedAccounts.length > 0;
  const isSelectionValid =
    hasSelected &&
    (rowSelection.selectedRowKeys.every(
      (selectedAccountId) =>
        data.find((account) => account.id === selectedAccountId)?.debtAmount > 0,
    ) ||
      rowSelection.selectedRowKeys.length === 1);
  const selectionTooltipMessage = hasSelected
    ? "Please select a single account with a zero balance if you wish to make an overpayment"
    : "Select 1 or more accounts to continue";

  const onPaymentTypeChange = (e) => {
    if (e.target.value) {
      dispatch(setAsPaymentPlan());
    } else {
      dispatch(setAsOneTimePayment());
    }
  };

  const onPaidToChange = (e) => {
    dispatch(setPaidTo(e.target.value));
  };

  const onZeroBalanceAccountVisibilityChange = (enabled) => {
    if (!enabled) {
      dispatch(
        setSelectedAccounts(
          rowSelection.selectedRowKeys.filter(
            (selectedAccountId) =>
              data.find((account) => account.id === selectedAccountId)?.debtAmount > 0,
          ),
        ),
      );
    }
    dispatch(setIsZeroBalanceAccountVisibleOption(enabled));
  };

  const [form] = Form.useForm();

  const initialValues = {
    settled: paymentsSlice.settled,
    partialPaymentAmount: paymentsSlice.partialPaymentAmount,
    originalTotalDue: debtTotal,
  };

  const onOneTimePaymentFinish = (values) => {
    let { partialPaymentAmount } = values;
    partialPaymentAmount = currency(partialPaymentAmount);
    const originalTotalAmount = debtTotal;
    let totalAmount;
    if (
      paymentsSlice.paymentIntentType === "partial" ||
      paymentsSlice.paymentIntentType === "settlement"
    ) {
      totalAmount = partialPaymentAmount;
    } else {
      totalAmount = originalTotalAmount;
    }

    const paymentsSchedule = getPaymentsScheduleForOneTimePayment({
      accountIds: paymentsSlice.selectedAccounts,
      paymentAmount: totalAmount.value,
    });
    const downPayment = 0;
    dispatch(
      setOneTimePaymentsSchedule({
        partialPaymentAmount: partialPaymentAmount.value,
        originalTotalAmount: originalTotalAmount.value,
        totalAmount: totalAmount.value,
        downPayment,
        paymentsSchedule,
      }),
    );
    dispatch(getNextView());
  };

  const onPaymentPlanFinish = (values) => {
    let { partialPaymentAmount } = values;
    partialPaymentAmount = currency(partialPaymentAmount, { precision: 4 });
    let totalAmount = currency(0, { precision: 4 });
    const originalTotalAmount = debtTotal;
    if (
      paymentsSlice.paymentIntentType === "partial" ||
      paymentsSlice.paymentIntentType === "settlement"
    ) {
      totalAmount = partialPaymentAmount;
    } else {
      totalAmount = originalTotalAmount;
    }

    dispatch(
      setupAsPaymentPlan({
        partialPaymentAmount: partialPaymentAmount.value,
        originalTotalAmount: originalTotalAmount.value,
        totalAmount: totalAmount.value,
      }),
    );
    dispatch(getNextView());
  };

  return (
    <div>
      <BottomSpacedStrong>Payment Configuration</BottomSpacedStrong>
      <StyledHeader>Paid to</StyledHeader>
      <Radio.Group onChange={onPaidToChange} value={paymentsSlice.paidTo}>
        <Radio value="agency">Agency</Radio>
        <Radio value="creditor">Client</Radio>
        {isAgencyUserType && <Radio value="forwarding_entity">Forwarding Entity</Radio>}
      </Radio.Group>
      <br />
      <br />
      {isAgencyUserType && paymentsSlice.paidTo === "agency" ? (
        <>
          <StyledHeader>Payment Type</StyledHeader>
          <Radio.Group onChange={onPaymentTypeChange} value={paymentsSlice.isPaymentPlan}>
            <Radio value={false}>One Time Payment</Radio>
            <Radio value disabled={!isPaymentsScheduleAuthorized}>
              Setup a Payment Plan
            </Radio>
          </Radio.Group>
          <br />
          <br />
        </>
      ) : null}
      <StyledAccountsHeader>Accounts</StyledAccountsHeader>
      <Flex justify="flex-end" align="end">
        <Space>
          <Switch
            checked={isZeroBalanceAccountVisible}
            onChange={onZeroBalanceAccountVisibilityChange}
          />
          <StyledSwitchLabel>Show zero-balance accounts</StyledSwitchLabel>
        </Space>
      </Flex>
      <StyledTable
        loading={isLoading}
        bordered
        pagination={false}
        rowSelection={rowSelection}
        columns={columns}
        dataSource={data}
      />
      <div style={{ marginBottom: 16, marginTop: 16 }}>
        <span>Amount Due Across Selected Accounts: </span>
        <StyledValue>{hasSelected ? `${formatCurrency(debtTotal)}` : "$0.00"}</StyledValue>
      </div>
      <Divider />
      <Form
        form={form}
        // colon={false}
        labelAlign="left"
        layout="vertical"
        initialValues={initialValues}
        validateMessages={{ required: "This is a required field" }}
        onFinish={paymentsSlice.isPaymentPlan ? onPaymentPlanFinish : onOneTimePaymentFinish}
      >
        <PaymentConfiguration />
        <Row>
          <Form.Item>
            <Tooltip title={isSelectionValid ? "" : selectionTooltipMessage}>
              <Button type="primary" htmlType="submit" disabled={!isSelectionValid}>
                Continue
              </Button>
            </Tooltip>
          </Form.Item>
        </Row>
      </Form>
    </div>
  );
}

export default SelectAccountsForPayment;
